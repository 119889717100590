import React, { useContext } from "react";
import toast from "react-hot-toast";
import SideNavigation from "../components/SideNavigation";

import CartContext from "../../data/cart-context";
import { NumberFormatBase } from "react-number-format";

const Wishlist = () => {
      const cartCtx = useContext(CartContext);
      const login = localStorage.getItem("isLoggedIn");
      const user = JSON.parse(localStorage.getItem("userData"));

      function handleAddCartSubmit(slug, e) {
            if (login) {
                  cartCtx.addCartSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      function handleRemoveWishlist(slug, e) {
            if (login) {
                  cartCtx.removeWishlistSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      return (
            <>
                  <div className="dashboard__container">
                        <div className="dashboard__content">
                              <SideNavigation />
                              <div className="dashboard__content-container">
                                    <div className="overflow-hidden">
                                          <h2 className="dashboard__content-title">
                                                My Wishlist
                                          </h2>
                                          <table className="table table-bordered table-hover">
                                                <thead className="table-light">
                                                      <tr>
                                                            <td>#</td>
                                                            <td>
                                                                  Product Name
                                                            </td>
                                                            <td>Price</td>
                                                            <td></td>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {cartCtx.totalWishlist >
                                                      0 ? (
                                                            cartCtx.wishlists.map(
                                                                  (
                                                                        wishlist,
                                                                        index
                                                                  ) => (
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <td>
                                                                                    {
                                                                                          ++index
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          wishlist.product_name
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <NumberFormatBase
                                                                                          displayType={
                                                                                                "text"
                                                                                          }
                                                                                          thousandSeparator={
                                                                                                true
                                                                                          }
                                                                                          thousandsGroupStyle="lakh"
                                                                                          prefix={
                                                                                                "Rs "
                                                                                          }
                                                                                          value={
                                                                                                wishlist.product_price
                                                                                          }
                                                                                    />
                                                                              </td>
                                                                              <td>
                                                                                    <button
                                                                                          className="text-dark fs-5"
                                                                                          onClick={(
                                                                                                e
                                                                                          ) =>
                                                                                                handleAddCartSubmit(
                                                                                                      wishlist.product_slug,
                                                                                                      e
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <i className="fa-regular fa-cart-shopping vertical-align"></i>
                                                                                    </button>
                                                                                    <button
                                                                                          className="text-danger fs-5"
                                                                                          onClick={(
                                                                                                e
                                                                                          ) =>
                                                                                                handleRemoveWishlist(
                                                                                                      wishlist.slug,
                                                                                                      e
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <i className="fa-regular fa-xmark vertical-align"></i>
                                                                                    </button>
                                                                              </td>
                                                                        </tr>
                                                                  )
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              4
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No item
                                                                        found
                                                                  </td>
                                                            </tr>
                                                      )}
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Wishlist;
