import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./style.css";
import MetaContext from "../../data/MetaContext";

const Blog = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("blogs");

      const [blogs, setBlogs] = useState([]);
      const [blogsLength, setBlogsLength] = useState([]);
      const [relatedBlogs, setRelatedBlogs] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBlogsLength(response.data.blogs.length);
                              setBlogs(response.data.blogs);
                              setRelatedBlogs(response.data.recentBlogs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta.seo_title || metaCtx.meta.title}
                  </h1>
                  <section className="blog-standard-section pt-3">
                        <div className="container">
                              <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                          <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                          </li>
                                          <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                          >
                                                Blog & News
                                          </li>
                                    </ol>
                              </nav>
                              <div className="row gy-5">
                                    {blogsLength > 0 ? (
                                          <>
                                                <div className="col col-md-8">
                                                      <div className="blog-standard-area">
                                                            {blogsLength > 0 &&
                                                                  blogs?.map(
                                                                        (
                                                                              blog,
                                                                              index
                                                                        ) => (
                                                                              <div
                                                                                    className="blog-standard-single shadow rounded bg-white"
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <div className="image mb-0">
                                                                                          <Link
                                                                                                to={`/blogs/${blog.slug}`}
                                                                                          >
                                                                                                <img
                                                                                                      src={
                                                                                                            blog.image
                                                                                                      }
                                                                                                      style={{
                                                                                                            width: "100%",
                                                                                                            maxHeight:
                                                                                                                  "426px",
                                                                                                      }}
                                                                                                      alt={
                                                                                                            blog.title
                                                                                                      }
                                                                                                      referrerPolicy="no-referrer"
                                                                                                />
                                                                                          </Link>
                                                                                    </div>
                                                                                    <div className="text ps-3 pe-3 pb-3">
                                                                                          <h2>
                                                                                                <Link
                                                                                                      to={`/blogs/${blog.slug}`}
                                                                                                >
                                                                                                      {
                                                                                                            blog.title
                                                                                                      }
                                                                                                </Link>
                                                                                          </h2>
                                                                                          <ul className="post-meta-list mt-0">
                                                                                                <li>
                                                                                                      <img
                                                                                                            src="/images/icons/user.svg"
                                                                                                            alt="User"
                                                                                                            referrerPolicy="no-referrer"
                                                                                                      />
                                                                                                      <span>
                                                                                                            By:
                                                                                                            eSecurity
                                                                                                            Cart{" "}
                                                                                                      </span>
                                                                                                </li>
                                                                                                <li>
                                                                                                      <img
                                                                                                            src="/images/icons/calendr.svg"
                                                                                                            alt="Calendar"
                                                                                                            referrerPolicy="no-referrer"
                                                                                                      />
                                                                                                      <span>
                                                                                                            Date:{" "}
                                                                                                            {
                                                                                                                  blog.date
                                                                                                            }{" "}
                                                                                                      </span>
                                                                                                </li>
                                                                                          </ul>
                                                                                          <p className="pb-0">
                                                                                                {
                                                                                                      blog.summary
                                                                                                }
                                                                                          </p>
                                                                                          <Link
                                                                                                to={`/blogs/${blog.slug}`}
                                                                                                className="read-more"
                                                                                          >
                                                                                                Read
                                                                                                More
                                                                                          </Link>
                                                                                    </div>
                                                                              </div>
                                                                        )
                                                                  )}
                                                      </div>
                                                </div>
                                                <Sidebar
                                                      relatedBlogs={
                                                            relatedBlogs
                                                      }
                                                />
                                          </>
                                    ) : (
                                          <div className="col-lg-12 py-5 mb-5 bg-white">
                                                <h4 className="text-center">
                                                      Coming Soon
                                                </h4>
                                          </div>
                                    )}
                              </div>
                        </div>
                  </section>
            </>
      );
};

export default Blog;
