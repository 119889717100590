import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const ReferPage = () => {
      return (
            <>
                  <div className="container pt-3">
                        <nav
                              aria-label="breadcrumb"
                              className="d-none d-lg-block"
                        >
                              <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                          <Link to="/">Home</Link>
                                    </li>
                                    <li
                                          className="breadcrumb-item active"
                                          aria-current="page"
                                    >
                                          Affiliate Marketing
                                    </li>
                              </ol>
                        </nav>
                        <section className="blog-standard-section pt-3 pb-5">
                              <div className="card">
                                    <div className="card-body">
                                          <div className="card-text">
                                                <figure className="wp-block-image is-resized">
                                                      <img
                                                            decoding="async"
                                                            referrerPolicy="no-referrer"
                                                            src="/images/refer.jpg"
                                                            alt=""
                                                            className="wp-image-207 w-100"
                                                            width="1140"
                                                            height="378"
                                                      />
                                                </figure>
                                                <h2>How IT WORKS</h2>
                                                <div
                                                      className="wp-block-media-text alignwide is-stacked-on-mobile"
                                                      style={{
                                                            gridTemplateColumns:
                                                                  "18% auto",
                                                      }}
                                                >
                                                      <figure className="wp-block-media-text__media">
                                                            <img
                                                                  decoding="async"
                                                                  referrerPolicy="no-referrer"
                                                                  loading="lazy"
                                                                  width="162"
                                                                  height="116"
                                                                  src="/images/like.png"
                                                                  alt=""
                                                                  className="wp-image-209"
                                                            />
                                                      </figure>
                                                      <div className="wp-block-media-text__content">
                                                            <p className="has-large-font-size">
                                                                  1. Register on
                                                                  our website
                                                            </p>
                                                            <p>
                                                                  <a
                                                                        rel="noreferrer noopener"
                                                                        aria-label="Sign Up (opens in a new tab)"
                                                                        href="/register"
                                                                        target="_blank"
                                                                        style={{
                                                                              color: "#e86b19",
                                                                        }}
                                                                  >
                                                                        <em>
                                                                              Sign
                                                                              Up
                                                                        </em>
                                                                  </a>
                                                                  <em>
                                                                        {" "}
                                                                        if you
                                                                        haven’t
                                                                        joined
                                                                        already.&nbsp;
                                                                  </em>
                                                                  <a
                                                                        rel="noreferrer noopener"
                                                                        aria-label="Login (opens in a new tab)"
                                                                        style={{
                                                                              color: "#e86b19",
                                                                        }}
                                                                        href="/login"
                                                                        target="_blank"
                                                                  >
                                                                        <em>
                                                                              Login
                                                                        </em>
                                                                  </a>
                                                                  <em>
                                                                        &nbsp;for
                                                                        existing
                                                                        account.
                                                                  </em>
                                                            </p>
                                                      </div>
                                                </div>
                                                <div
                                                      className="wp-block-media-text alignwide has-media-on-the-right is-stacked-on-mobile"
                                                      style={{
                                                            gridTemplateColumns:
                                                                  "auto 22%",
                                                      }}
                                                >
                                                      <figure className="wp-block-media-text__media">
                                                            <img
                                                                  decoding="async"
                                                                  loading="lazy"
                                                                  referrerPolicy="no-referrer"
                                                                  width="191"
                                                                  height="190"
                                                                  src="/images/teamwork.png"
                                                                  alt=""
                                                                  className="wp-image-210"
                                                            />
                                                      </figure>
                                                      <div className="wp-block-media-text__content">
                                                            <p className="has-large-font-size">
                                                                  2. Invite your
                                                                  Friends
                                                            </p>
                                                            <p>
                                                                  <em>
                                                                        Think of
                                                                        all your
                                                                        friends
                                                                        who shop
                                                                        online
                                                                        regularly
                                                                        and
                                                                        invite
                                                                        them.
                                                                        The more
                                                                        you
                                                                        invite,
                                                                        the more
                                                                        you
                                                                        earn.
                                                                  </em>
                                                            </p>
                                                      </div>
                                                </div>
                                                <div
                                                      className="wp-block-media-text alignwide is-stacked-on-mobile"
                                                      style={{
                                                            gridTemplateColumns:
                                                                  "19% auto",
                                                      }}
                                                >
                                                      <figure className="wp-block-media-text__media">
                                                            <img
                                                                  decoding="async"
                                                                  loading="lazy"
                                                                  width="156"
                                                                  height="144"
                                                                  src="/images/shopping-cart.png"
                                                                  alt=""
                                                                  referrerPolicy="no-referrer"
                                                                  className="wp-image-211"
                                                            />
                                                      </figure>
                                                      <div className="wp-block-media-text__content">
                                                            <p className="has-large-font-size">
                                                                  3. Your
                                                                  Friends Shop
                                                                  &amp; Earn
                                                                  Cashback
                                                            </p>
                                                            <p>
                                                                  <em>
                                                                        Your
                                                                        friends
                                                                        shop
                                                                        through
                                                                        our
                                                                        website
                                                                        and
                                                                        start
                                                                        earning
                                                                        cashback
                                                                        for
                                                                        themselves.
                                                                  </em>
                                                            </p>
                                                      </div>
                                                </div>
                                                <div
                                                      className="wp-block-media-text alignwide has-media-on-the-right is-stacked-on-mobile"
                                                      style={{
                                                            gridTemplateColumns:
                                                                  "auto 11%",
                                                      }}
                                                >
                                                      <figure className="wp-block-media-text__media">
                                                            <img
                                                                  decoding="async"
                                                                  loading="lazy"
                                                                  width="80"
                                                                  height="162"
                                                                  src="/images/growth.png"
                                                                  alt=""
                                                                  className="wp-image-212"
                                                                  referrerPolicy="no-referrer"
                                                            />
                                                      </figure>
                                                      <div className="wp-block-media-text__content">
                                                            <p className="has-large-font-size">
                                                                  4. You earn
                                                                  whenever your
                                                                  friend's shop
                                                            </p>
                                                            <p>
                                                                  <em>
                                                                        Whenever
                                                                        your
                                                                        friends
                                                                        shop
                                                                        online
                                                                        from our
                                                                        website,
                                                                        you get
                                                                        some %
                                                                        credited
                                                                        to your
                                                                        account
                                                                        as well.
                                                                  </em>
                                                            </p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </section>
                  </div>
            </>
      );
};

export default ReferPage;
