import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ relatedBlogs }) => {
      return (
            <>
                  <div className="col col-md-4">
                        <div className="blog-sidebar">
                              <div className="blog-widget-item">
                                    <div className="search-area">
                                          <div className="blog-widget-body">
                                                <form>
                                                      <div className="search-with-btn">
                                                            <input
                                                                  type="text"
                                                                  placeholder="Search Post.."
                                                            />
                                                            <button type="submit">
                                                                  <i className="fa-regular fa-magnifying-glass"></i>
                                                            </button>
                                                      </div>
                                                </form>
                                          </div>
                                    </div>
                              </div>
                              {relatedBlogs.length > 0 && (
                                    <div className="blog-widget-item">
                                          <div className="recent-blog">
                                                <h5 className="blog-widget-title">
                                                      Recent post
                                                </h5>
                                                <div className="blog-widget-body">
                                                      <ul className="recent-post-list">
                                                            {relatedBlogs.length >
                                                                  0 &&
                                                                  relatedBlogs.map(
                                                                        (
                                                                              blog,
                                                                              index
                                                                        ) => (
                                                                              <li
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <div className="recent-post-item">
                                                                                          <div className="recent-post-img">
                                                                                                <img
                                                                                                      src={
                                                                                                            blog.image
                                                                                                      }
                                                                                                      className="rounded"
                                                                                                      style={{
                                                                                                            width: "68px",
                                                                                                            height: "68px",
                                                                                                      }}
                                                                                                      alt={
                                                                                                            blog.title
                                                                                                      }
                                                                                                      referrerPolicy="no-referrer"
                                                                                                />
                                                                                          </div>
                                                                                          <div className="details">
                                                                                                <h4>
                                                                                                      <Link
                                                                                                            to={`/blogs/${blog.slug}`}
                                                                                                      >
                                                                                                            {
                                                                                                                  blog.title
                                                                                                            }
                                                                                                      </Link>
                                                                                                </h4>
                                                                                                <span className="date">
                                                                                                      <i className="ti-timer"></i>
                                                                                                      {
                                                                                                            blog.date
                                                                                                      }
                                                                                                </span>
                                                                                          </div>
                                                                                    </div>
                                                                              </li>
                                                                        )
                                                                  )}
                                                      </ul>
                                                </div>
                                          </div>
                                    </div>
                              )}
                        </div>
                  </div>
            </>
      );
};

export default Sidebar;
