import { Button } from "react-bootstrap";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
      background-color: rgb(232, 107, 25);
      border-color: rgb(232, 107, 25);
      box-shadow: none;
      text-align: center;
      margin-bottom: ${(props) => props.marginBottom || "1.5rem"};
      padding: 0.5rem 2.5rem;
      margin-top: ${(props) => props.marginTop || "0.5rem"};
      width: ${(props) => props.width || "180px"};
      height: 40px;

      &:hover,
      &:disabled {
            background-color: rgb(232 107 25 / 90%);
      }

      .spinner-border {
            width: 20px;
            height: 20px;
      }

      &:first-child:active {
            background-color: rgb(232 107 25 / 90%);
            box-shadow: none !important;
            border-color: transparent;
      }
`;
