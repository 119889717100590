import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const FooterContext = createContext({
      categories: [],
      brands: [],
      loading: false,
      filteredBrands: [],
});

export const FooterContextProvider = (props) => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [brands, setBrands] = useState([]);
      const [filteredBrands, setFilteredBrands] = useState([]);

      const lodaData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/footer`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategories(response.data.categories);
                              setBrands(response.data.brands);
                              setFilteredBrands(response.data.filteredBrands);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            lodaData();
      }, []);

      const context = {
            categories: categories,
            brands: brands,
            loading: loading,
            filteredBrands: filteredBrands,
      };

      return (
            <FooterContext.Provider value={context}>
                  {props.children}
            </FooterContext.Provider>
      );
};

export default FooterContext;
