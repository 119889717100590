import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import MetaContext from "../../data/MetaContext";

const Training = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("trainings");

      const [trainings, setTrainings] = useState([]);
      const [loading, setLoading] = useState(false);
      const [trainingsLength, setTrainingsLength] = useState([]);
      const [relatedTrainings, setRelatedTrainings] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/trainings`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTrainingsLength(
                                    response.data.trainings.length
                              );
                              setTrainings(response.data.trainings);
                              setRelatedTrainings(
                                    response.data.relatedTrainings
                              );
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta.seo_title || metaCtx.meta.title}
                  </h1>
                  <section className="blog-standard-section pt-3">
                        <div className="container">
                              <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                          <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                          </li>
                                          <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                          >
                                                Trianing
                                          </li>
                                    </ol>
                              </nav>
                              <div className="row gy-5">
                                    {trainingsLength > 0 ? (
                                          <div className="col col-md-8">
                                                <div className="blog-standard-area">
                                                      {trainingsLength > 0 &&
                                                            trainings.map(
                                                                  (
                                                                        training,
                                                                        index
                                                                  ) => (
                                                                        <div
                                                                              className="blog-standard-single shadow rounded bg-white"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <div className="image mb-0">
                                                                                    <Link
                                                                                          to={`/trainings/${training.slug}`}
                                                                                    >
                                                                                          <img
                                                                                                src={
                                                                                                      training.image
                                                                                                }
                                                                                                style={{
                                                                                                      width: "100%",
                                                                                                      maxHeight:
                                                                                                            "426px",
                                                                                                }}
                                                                                                alt={
                                                                                                      training.title
                                                                                                }
                                                                                                referrerPolicy="no-referrer"
                                                                                          />
                                                                                    </Link>
                                                                              </div>
                                                                              <div className="text ps-3 pe-3 pb-3">
                                                                                    <h2>
                                                                                          <Link
                                                                                                to={`/trainings/${training.slug}`}
                                                                                          >
                                                                                                {
                                                                                                      training.title
                                                                                                }
                                                                                          </Link>
                                                                                    </h2>
                                                                                    <ul className="post-meta-list mt-0">
                                                                                          <li>
                                                                                                <img
                                                                                                      src="/images/icons/user.svg"
                                                                                                      alt="User"
                                                                                                      referrerPolicy="no-referrer"
                                                                                                />
                                                                                                <span>
                                                                                                      By:
                                                                                                      eSecurity
                                                                                                      Cart{" "}
                                                                                                </span>
                                                                                          </li>
                                                                                          <li>
                                                                                                <img
                                                                                                      src="/images/icons/calendr.svg"
                                                                                                      alt="Calendar"
                                                                                                      referrerPolicy="no-referrer"
                                                                                                />
                                                                                                <span>
                                                                                                      Date:{" "}
                                                                                                      {
                                                                                                            training.date
                                                                                                      }{" "}
                                                                                                </span>
                                                                                          </li>
                                                                                    </ul>
                                                                                    <p className="pb-0">
                                                                                          {
                                                                                                training.summary
                                                                                          }
                                                                                    </p>
                                                                                    <Link
                                                                                          to={`/trainings/${training.slug}`}
                                                                                          className="read-more"
                                                                                    >
                                                                                          Read
                                                                                          More
                                                                                    </Link>
                                                                              </div>
                                                                        </div>
                                                                  )
                                                            )}
                                                </div>
                                          </div>
                                    ) : (
                                          <div className="col-lg-12 py-5 mb-5 bg-white">
                                                <h4 className="text-center">
                                                      Coming Soon
                                                </h4>
                                          </div>
                                    )}

                                    {trainingsLength > 0 && (
                                          <Sidebar
                                                relatedTrainings={
                                                      relatedTrainings
                                                }
                                          />
                                    )}
                              </div>
                        </div>
                  </section>
            </>
      );
};

export default Training;
