import {Link} from 'react-router-dom';

const CheckoutForm = ({
    handleForm,
    user,
    userDetail,
    setCompany,
    setPhoneNumber,
    setState,
    setCity,
    setStreet,
    setCountry,
    isLoading,
    setPayment,
    setPostal,
}) => {
    return (
        <>
        <form className="checkout__form" onSubmit={handleForm}>
            <div className="form-group">
                <h2 className="font-semibold font-serif text-base text-gray-700">01. Personal Details</h2>
                <div className="cart__form-grid">
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Full Name</label>
                        <div className="position-relative">
                            <input type="text" defaultValue={user.name} readOnly className="cart__form-input" />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Company Name (Optional)</label>
                        <div className="position-relative">
                            <input type="text" className="cart__form-input" defaultValue={userDetail ? userDetail.company : ''} onChange={(event) => setCompany(event.target.value)} />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Email address</label>
                        <div className="position-relative">
                            <input defaultValue={user.email} readOnly type="email" className="cart__form-input" />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Phone number</label>
                        <div className="position-relative">
                            <input defaultValue={userDetail ? userDetail.phone : ''} type="tel" className="cart__form-input" onChange={(event) => setPhoneNumber(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group mt-12">
                <h2 className="font-semibold font-serif text-base text-gray-700 pt-3">02. Shipping Details</h2>
                <div className="cart__form-grid mb-8">
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">State / Zone</label>
                        <div className="position-relative">
                            <input defaultValue={userDetail ? userDetail.state : ''} type="text" className="cart__form-input" onChange={(event) => setState(event.target.value)} />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Town / City</label>
                        <div className="position-relative">
                            <input defaultValue={userDetail ? userDetail.city : ''} type="text" className="cart__form-input" onChange={(event) => setCity(event.target.value)} />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Street address</label>
                        <div className="position-relative">
                            <input defaultValue={userDetail ? userDetail.street : ''} type="text" className="cart__form-input" onChange={(event) => setStreet(event.target.value)} />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">Country</label>
                        <div className="position-relative">
                            <input defaultValue={userDetail ? userDetail.country : ''} type="text" className="cart__form-input" onChange={(event) => setCountry(event.target.value)} />
                        </div>
                    </div>
                    <div className="cart__form-grid6">
                        <label className="block text-gray-500 font-medium text-sm leading-none mb-2">ZIP / Postal</label>
                        <div className="position-relative">
                            <input defaultValue={userDetail ? userDetail.postal : ''} type="text" className="cart__form-input" onChange={(event) => setPostal(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group mt-12">
                <h2 className="font-semibold font-serif text-base text-gray-700 pt-3">03. Payment Details</h2>
                <div className="cart__form-grid">
                    <div className="cart__form-grid6">
                        <div className="px-3 py-4 card border border-gray-200 bg-white rounded-md"><label className="cursor-pointer label">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-baseline">
                                    <span className="text-xl me-3 text-gray-400">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M47.5 104H432V51.52a16 16 0 00-19.14-15.69l-368 60.48a16 16 0 00-12 10.47A39.69 39.69 0 0147.5 104zm416 24h-416a16 16 0 00-16 16v288a16 16 0 0016 16h416a16 16 0 0016-16V144a16 16 0 00-16-16zM368 320a32 32 0 1132-32 32 32 0 01-32 32z">
                                            </path>
                                            <path d="M31.33 259.5V116c0-12.33 5.72-18.48 15.42-20 35.2-5.53 108.58-8.5 108.58-8.5s-8.33 16-27.33 16V128c18.5 0 31.33 23.5 31.33 23.5L84.83 236z"></path>
                                        </svg>
                                    </span>
                                    <h6 className="font-serif font-medium text-sm text-gray-600">Cash On Delivery</h6>
                                </div>
                                <input type="radio" className="form-radio outline-none focus:ring-0 text-emerald-500" value="Cash On Delivery" onChange={(event) => setPayment(event.target.value)} />
                            </div>
                        </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cart__address">
                <div className="cart__form-grid6">
                    <Link className="shoping__continue" to="/">
                        <span className="text-xl me-2">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M112 160l-64 64 64 64"></path>
                                <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M64 224h294c58.76 0 106 49.33 106 108v20"></path>
                            </svg>
                        </span>Continue Shopping
                    </Link>
                </div>
                <div className="cart__form-grid6">
                    <button className="bg-emerald-500 hover:bg-emerald-600 shoping__confirm">
                        <span className="d-flex justify-content-center text-center"> Confirm
                            <span className="text-xl ms-2">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144m124-144H100"></path>
                                </svg>
                            </span>
                            {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}
                        </span>
                    </button>
                </div>
            </div>
        </form>
        </>
    )
}

export default CheckoutForm;
