import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaContext from "../../data/MetaContext";
import { Col, Row } from "react-bootstrap";

const Catalogue = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("catalogues");

      const [catalogues, setCatalogues] = useState([]);
      const [catalogueLength, setCatalogueLength] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/catalogues`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result == "success") {
                              setCatalogueLength(
                                    response.data.catalogues.length
                              );
                              setCatalogues(response.data.catalogues);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="category__body">
                        <div className="product__details-container">
                              <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                          <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                          </li>
                                          <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                          >
                                                Catalogue
                                          </li>
                                    </ol>
                              </nav>
                              <div className="product__details-content">
                                    <Row>
                                          <Col xs={12}>
                                                <Row>
                                                      {catalogueLength > 0 ? (
                                                            catalogues.map(
                                                                  (
                                                                        catalogue,
                                                                        index
                                                                  ) => (
                                                                        <Col
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    3
                                                                              }
                                                                              sm={
                                                                                    4
                                                                              }
                                                                              className="col-tn position-relative"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Link
                                                                                    to={`/catalogues/${catalogue.slug}`}
                                                                              >
                                                                                    <div className="product-item text-center p-0">
                                                                                          <div className="w-100">
                                                                                                <img
                                                                                                      src={
                                                                                                            catalogue.image
                                                                                                      }
                                                                                                      className="img-responsive hover border p-3 w-100"
                                                                                                      alt={
                                                                                                            catalogue.title
                                                                                                      }
                                                                                                      referrerPolicy="no-referrer"
                                                                                                />
                                                                                          </div>
                                                                                          <div className="fs-5 w-100 text-center">
                                                                                                <p>
                                                                                                      {
                                                                                                            catalogue.title
                                                                                                      }
                                                                                                </p>
                                                                                          </div>
                                                                                    </div>
                                                                              </Link>
                                                                        </Col>
                                                                  )
                                                            )
                                                      ) : (
                                                            <h4 className="text-center">
                                                                  Coming Soon
                                                            </h4>
                                                      )}
                                                </Row>
                                          </Col>
                                    </Row>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Catalogue;
