import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../layouts/Loading";
import axios from "axios";
import MetaContext from "../../../data/MetaContext";

const BrandList = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("brands");

      const [loading, setLoading] = useState(false);
      const [brands, setBrands] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/brands`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setBrands(response.data.brands);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta.seo_title || metaCtx.meta.title}
                  </h1>
                  <div className="product__details">
                        <div className="product__details-container">
                              <div className="product__breadcrumb d-none d-md-block">
                                    <ol className="product__breadcrumb-content">
                                          <li className="product__breadcrumb-link hover:text-emerald-500">
                                                <Link to="/">Home</Link>
                                          </li>
                                          <li className="product__breadcrumb-item">
                                                <svg
                                                      stroke="currentColor"
                                                      fill="none"
                                                      strokeWidth="2"
                                                      viewBox="0 0 24 24"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      height="1em"
                                                      width="1em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                >
                                                      <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                          </li>
                                          <li className="product__breadcrumb-title">
                                                Brand List
                                          </li>
                                    </ol>
                              </div>
                              <div
                                    className="product__details-content p-0"
                                    style={{ backgroundColor: "transparent" }}
                              >
                                    {!loading ? (
                                          <>
                                                <div className="brand__container">
                                                      {brands.map(
                                                            (brand, index) => (
                                                                  <Link
                                                                        to={`/brands/${brand.slug}`}
                                                                        className="prouduct__image-container"
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <div className="product__image-content">
                                                                              <img
                                                                                    src={
                                                                                          brand.image
                                                                                    }
                                                                                    className="product__image"
                                                                                    referrerPolicy="no-referrer"
                                                                              />
                                                                        </div>
                                                                  </Link>
                                                            )
                                                      )}
                                                </div>
                                          </>
                                    ) : (
                                          <>
                                                <Loading />
                                          </>
                                    )}
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BrandList;
