import React, { lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "../frontend/styles/style.css";

import Catalogue from "../frontend/catalogues/Catalogue";
import TrackingOrder from "../frontend/orders/TrackingOrder";
import BrandList from "../components/frontend/brands/BrandList";
import BrandProduct from "../components/frontend/brands/BrandProduct";
import Search from "../frontend/pages/Search";
import Wishlist from "../backend/wishlists/Wishlist";
import Cart from "../backend/carts/Cart";
import Checkout from "../backend/checkout/Checkout";
import Order from "../backend/order/Order";
import FaqList from "../frontend/faqs/FaqList";
import Certificate from "../frontend/certificate/Certificate";
import Blog from "../frontend/blog/Blog";
import BlogDetail from "../frontend/blog/BlogDetail";
import Training from "../frontend/training/Training";
import TrainingDetail from "../frontend/training/TrainingDetail";
import ReferPage from "../frontend/refers/ReferPage";
import Waste from "../frontend/waste/Waste";
import Coupon from "../frontend/coupon/Coupon";
import OrderDetail from "../frontend/orders/OrderDetail";
import Wallet from "../backend/wallet/Wallet";
import GoogleLogin from "../auth/google/GoogleLogin";
import EmailVerify from "../auth/email/EmailVerify";
import { ForgetPassword } from "../auth";

const Category = lazy(() => import("../frontend/categories/Category"));
const HomePage = lazy(() => import("../frontend/home/HomePage"));

const PackageList = lazy(() => import("../frontend/packages/PackageList"));

const Service = lazy(() => import("../frontend/services/Service"));
const ServiceDetail = lazy(() => import("../frontend/services/ServiceDetail"));
const ServiceRequest = lazy(() =>
      import("../frontend/services/ServiceRequest")
);

const ContactUs = lazy(() => import("../frontend/contact/ContactUs"));
const PrivacyPolicy = lazy(() => import("../frontend/pages/PrivacyPolicy"));

const Complaint = lazy(() => import("../frontend/complaint/Complaint"));

const Career = lazy(() => import("../frontend/career/Career"));
const CareerDetail = lazy(() => import("../frontend/career/CareerDetail"));

const Compare = lazy(() => import("../frontend/compare"));

const Login = lazy(() => import("../auth/Login"));
const Register = lazy(() => import("../auth/Register"));

const QuoteRequest = lazy(() => import("../frontend/quotes/QuoteRequest"));

const ProductDetail = lazy(() => import("../frontend/products/ProductDetail"));

const Profile = lazy(() => import("../backend/profile/Profile"));
const Dashboard = lazy(() => import("../backend/dashboard/Dashboard"));
const ChangePassword = lazy(() => import("../backend/profile/ChangePassword"));
const ProfileUpdate = lazy(() => import("../backend/profile/ProfileUpdate"));

const MyService = lazy(() => import("../backend/service/MyService"));

const PublicRouter = () => {
      useEffect(() => {
            const script = document.createElement("script");
            script.src = "/js/script.js";
            script.async = true;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                  document.body.removeChild(script);
            };
      }, [useLocation().pathname]);

      return (
            <>
                  <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route
                              path="/category/:categoryId"
                              element={<Category />}
                        />

                        <Route
                              path="/products/:productId"
                              element={<ProductDetail />}
                        />

                        {/* Page */}
                        <Route path="/faqs" element={<FaqList />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route
                              path="/pages/:pageId"
                              element={<PrivacyPolicy />}
                        />
                        <Route path="/certificates" element={<Certificate />} />

                        {/* Blog */}
                        <Route path="/blogs" element={<Blog />} />
                        <Route path="/blogs/:blogId" element={<BlogDetail />} />

                        {/* Training */}
                        <Route path="/trainings" element={<Training />} />
                        <Route
                              path="/trainings/:trainingId"
                              element={<TrainingDetail />}
                        />

                        {/* Refer */}
                        <Route
                              path="/affiliate-marketing"
                              element={<ReferPage />}
                        />

                        {/* E Waste */}
                        <Route path="e-waste" element={<Waste />} />
                        <Route path="/support-ticket" element={<Complaint />} />

                        {/* Career */}
                        <Route path="/career" element={<Career />} />
                        <Route
                              path="/career/:careerId"
                              element={<CareerDetail />}
                        />

                        {/* Servicie */}
                        <Route path="/services" element={<Service />} />
                        <Route
                              path="/services/:serviceId"
                              element={<ServiceDetail />}
                        />
                        <Route
                              path="/services/:serviceId/request"
                              element={<ServiceRequest />}
                        />

                        {/* Catalogue */}
                        <Route path="/catalogues" element={<Catalogue />} />

                        {/* Quote Request */}
                        <Route
                              path="/quote-request"
                              element={<QuoteRequest />}
                        />
                        <Route
                              path="/order-tracking"
                              element={<TrackingOrder />}
                        />

                        {/* Packages */}
                        <Route path="/packages" element={<PackageList />} />

                        {/* Brands */}
                        <Route path="/brands" element={<BrandList />} />
                        <Route
                              path="/brands/:brandId"
                              element={<BrandProduct />}
                        />

                        {/* Coupon */}
                        <Route path="/coupons" element={<Coupon />} />

                        {/* Compare */}
                        <Route path="/compare" element={<Compare />} />

                        <Route path="/products" element={<Search />} />

                        {/* Auth */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route
                              path="/forget-password"
                              element={<ForgetPassword />}
                        />

                        <Route
                              path="/dashboard/profile"
                              element={<Profile />}
                        />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route
                              path="/dashboard/change-password"
                              element={<ChangePassword />}
                        />
                        <Route
                              path="/dashboard/update-profile"
                              element={<ProfileUpdate />}
                        />

                        <Route path="/wishlists" element={<Wishlist />} />
                        <Route path="/carts" element={<Cart />} />
                        <Route path="/checkouts" element={<Checkout />} />

                        {/* Order */}
                        <Route
                              path="/dashboard/my-orders"
                              element={<Order />}
                        />
                        <Route
                              path="/dashboard/my-orders/:orderId"
                              element={<OrderDetail />}
                        />

                        <Route
                              path="/dashboard/my-service"
                              element={<MyService />}
                        />

                        {/* Wallet & Refer */}
                        <Route
                              path="/dashboard/wallet-refer"
                              element={<Wallet />}
                        />

                        {/* Social Login */}
                        <Route
                              path="/login/google/callback"
                              element={<GoogleLogin />}
                        />

                        <Route path="/email/verify" element={<EmailVerify />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
