import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

import { Navigate, useLocation } from "react-router-dom";

const GoogleLogin = () => {
      const [loading, setLoading] = useState(true);
      const location = useLocation();

      const [redirect, setRedirect] = useState(false);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/auth/callback${location.search}`,
                        {
                              headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        localStorage.setItem("isLoggedIn", true);
                        localStorage.setItem(
                              "token",
                              JSON.stringify(response.data.access_token)
                        );
                        localStorage.setItem(
                              "tokenExpiration",
                              new Date(
                                    new Date().getTime() +
                                          response.data.expires_in * 1000
                              )
                        );
                        setLoading(false);
                        setRedirect(true);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, []);

      if (redirect) {
            return (window.location.href = "/");
      }

      const login = localStorage.getItem("isLoggedIn");
      if (login) {
            return <Navigate to="/" />;
      }

      function DisplayLoading() {
            return (
                  <div className="text-center mt-5 py-5">
                        <Spinner />
                  </div>
            );
      }

      if (loading) {
            return <DisplayLoading />;
      }
};

export default GoogleLogin;
