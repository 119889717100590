import React, { useContext, useState } from "react";
import toast from "react-hot-toast";

import { NumberFormatBase } from "react-number-format";
import { Link } from "react-router-dom";
import CartContext from "../../data/cart-context";

const Cart = () => {
      const cartCtx = useContext(CartContext);
      const login = localStorage.getItem("isLoggedIn");
      const user = "";
      const [discount, setDiscount] = useState(0);

      function handleRemoveCartSubmit(slug, e) {
            if (login) {
                  cartCtx.removeCartSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      function handleAddWishlistSubmit(slug, e) {
            if (login) {
                  cartCtx.addWishlistSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      function handleUpdateCartSubmit(slug, e, quantity) {
            if (login) {
                  cartCtx.updateCartSubmit({
                        slug: slug,
                        email: user.email,
                        quantity: Number(quantity) + 1,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      function handleDecreaseCartSubmit(slug, e, quantity) {
            if (login) {
                  if (quantity != 1) {
                        cartCtx.updateCartSubmit({
                              slug: slug,
                              email: user.email,
                              quantity: quantity - 1,
                        });
                  }
            } else {
                  toast.success(`Login required!`);
            }
      }

      return (
            <>
                  <div className="cart__container">
                        <div className="cart__container-content">
                              <div className="cart__container-body">
                                    <div
                                          className="shoping__summary-content"
                                          style={{ maxHeight: "100%" }}
                                    >
                                          {cartCtx.carts.map(
                                                (product, index) => (
                                                      <div
                                                            className="shoping__summary-item"
                                                            key={index}
                                                      >
                                                            <div className="shoping__summary-img">
                                                                  <img
                                                                        src={
                                                                              product.image
                                                                        }
                                                                        width="60"
                                                                        height="60"
                                                                        alt={
                                                                              product.product_name
                                                                        }
                                                                        referrerPolicy="no-referrer"
                                                                  />
                                                            </div>
                                                            <div className="shoping__summary-title">
                                                                  <Link
                                                                        className="shoping__summary-link"
                                                                        to={`/products/${product.product_slug}`}
                                                                  >
                                                                        {
                                                                              product.product_name
                                                                        }
                                                                  </Link>
                                                                  {/* <span className="shoping__summary-price">Item Price <NumberFormatBase displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'Rs '} value={product.price} /></span> */}
                                                                  <div className="d-flex align-items-center justify-content-between pt-2">
                                                                        <div className="d-flex shoping__summary-amount text-align-center">
                                                                              <span className="pe-5">
                                                                                    Item
                                                                                    Price{" "}
                                                                                    <NumberFormatBase
                                                                                          displayType={
                                                                                                "text"
                                                                                          }
                                                                                          thousandSeparator={
                                                                                                true
                                                                                          }
                                                                                          thousandsGroupStyle="lakh"
                                                                                          prefix={
                                                                                                "Rs "
                                                                                          }
                                                                                          value={
                                                                                                product.price
                                                                                          }
                                                                                    />
                                                                              </span>
                                                                              <div className="shoping__summary-btnContainer me-5">
                                                                                    <button
                                                                                          onClick={(
                                                                                                e
                                                                                          ) =>
                                                                                                handleDecreaseCartSubmit(
                                                                                                      product.slug,
                                                                                                      e,
                                                                                                      product.qty
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <span className="shoping__summary-icon">
                                                                                                <svg
                                                                                                      stroke="currentColor"
                                                                                                      fill="none"
                                                                                                      strokeWidth="2"
                                                                                                      viewBox="0 0 24 24"
                                                                                                      strokeLinecap="round"
                                                                                                      strokeLinejoin="round"
                                                                                                      height="1em"
                                                                                                      width="1em"
                                                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                                                >
                                                                                                      <line
                                                                                                            x1="5"
                                                                                                            y1="12"
                                                                                                            x2="19"
                                                                                                            y2="12"
                                                                                                      ></line>
                                                                                                </svg>
                                                                                          </span>
                                                                                    </button>
                                                                                    <p className="text-sm font-semibold text-dark px-1">
                                                                                          {
                                                                                                product.qty
                                                                                          }
                                                                                    </p>
                                                                                    <button
                                                                                          onClick={(
                                                                                                e
                                                                                          ) =>
                                                                                                handleUpdateCartSubmit(
                                                                                                      product.slug,
                                                                                                      e,
                                                                                                      product.qty
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <span className="shoping__summary-icon">
                                                                                                <svg
                                                                                                      stroke="currentColor"
                                                                                                      fill="none"
                                                                                                      strokeWidth="2"
                                                                                                      viewBox="0 0 24 24"
                                                                                                      strokeLinecap="round"
                                                                                                      strokeLinejoin="round"
                                                                                                      height="1em"
                                                                                                      width="1em"
                                                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                                                >
                                                                                                      <line
                                                                                                            x1="12"
                                                                                                            y1="5"
                                                                                                            x2="12"
                                                                                                            y2="19"
                                                                                                      ></line>
                                                                                                      <line
                                                                                                            x1="5"
                                                                                                            y1="12"
                                                                                                            x2="19"
                                                                                                            y2="12"
                                                                                                      ></line>
                                                                                                </svg>
                                                                                          </span>
                                                                                    </button>
                                                                              </div>
                                                                              <span>
                                                                                    <NumberFormatBase
                                                                                          displayType={
                                                                                                "text"
                                                                                          }
                                                                                          thousandSeparator={
                                                                                                true
                                                                                          }
                                                                                          thousandsGroupStyle="lakh"
                                                                                          prefix={
                                                                                                "Rs "
                                                                                          }
                                                                                          value={
                                                                                                product.price *
                                                                                                product.qty
                                                                                          }
                                                                                    />
                                                                              </span>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                              <button
                                                                                    onClick={(
                                                                                          e
                                                                                    ) =>
                                                                                          handleAddWishlistSubmit(
                                                                                                product.product_slug,
                                                                                                e
                                                                                          )
                                                                                    }
                                                                                    className="hover:text-red-600 text-red-400 text-lg cursor-pointer"
                                                                              >
                                                                                    <i className="fa-regular fa-heart"></i>
                                                                              </button>
                                                                              <button
                                                                                    onClick={(
                                                                                          e
                                                                                    ) =>
                                                                                          handleRemoveCartSubmit(
                                                                                                product.slug,
                                                                                                e
                                                                                          )
                                                                                    }
                                                                                    className="hover:text-red-600 text-red-400 text-lg cursor-pointer"
                                                                              >
                                                                                    <svg
                                                                                          stroke="currentColor"
                                                                                          fill="none"
                                                                                          strokeWidth="2"
                                                                                          viewBox="0 0 24 24"
                                                                                          strokeLinecap="round"
                                                                                          strokeLinejoin="round"
                                                                                          height="1em"
                                                                                          width="1em"
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                          <polyline points="3 6 5 6 21 6"></polyline>
                                                                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                                          <line
                                                                                                x1="10"
                                                                                                y1="11"
                                                                                                x2="10"
                                                                                                y2="17"
                                                                                          ></line>
                                                                                          <line
                                                                                                x1="14"
                                                                                                y1="11"
                                                                                                x2="14"
                                                                                                y2="17"
                                                                                          ></line>
                                                                                    </svg>
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                )
                                          )}
                                    </div>
                              </div>
                              <div className="shoping__container">
                                    <div className="shoping__summary">
                                          <h2 className="font-semibold font-serif text-lg">
                                                Order Summary
                                          </h2>

                                          <div className="shoping__amount">
                                                Subtotal (Inc. TAX){" "}
                                                <span className="shoping__price">
                                                      Rs {cartCtx.cartSubTotal}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                eSecurity Offer Discount{" "}
                                                <span className="shoping__price">
                                                      Rs{" "}
                                                      {cartCtx.discountAmount}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                eSecurity Cashback{" "}
                                                <span className="shoping__price">
                                                      Rs{" "}
                                                      {cartCtx.cashbackAmount}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                Shipping Cost
                                                <span className="shoping__price">
                                                      Rs 0.00
                                                </span>
                                          </div>
                                          <div className="shoping__hr">
                                                <div className="shoping__total">
                                                      Total cost{" "}
                                                      <span className="font-serif font-extrabold text-lg text-capitalize">
                                                            {" "}
                                                            Rs{" "}
                                                            {
                                                                  cartCtx.totalAmount
                                                            }
                                                      </span>
                                                </div>
                                          </div>
                                          <div className="cart__form-grid6 mt-3">
                                                <Link
                                                      to="/checkouts"
                                                      className="bg-emerald-500 hover:bg-emerald-600 shoping__confirm"
                                                >
                                                      <span className="flex justify-center text-center text-white">
                                                            {" "}
                                                            Proceed to Checkout
                                                            <span className="text-xl ms-2">
                                                                  <svg
                                                                        stroke="currentColor"
                                                                        fill="currentColor"
                                                                        strokeWidth="0"
                                                                        viewBox="0 0 512 512"
                                                                        height="1em"
                                                                        width="1em"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                        <path
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              strokeWidth="48"
                                                                              d="M268 112l144 144-144 144m124-144H100"
                                                                        ></path>
                                                                  </svg>
                                                            </span>
                                                      </span>
                                                </Link>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Cart;
