import styled from "styled-components";

export const QuoteButton = styled.button`
      width: 80px;

      .spinner-border {
            width: 12px;
            height: 12px;
      }
`;
