import React, { useContext, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import UserContext from "../../data/UserContext";
import {
      DashboardIcon,
      LogoutIcon,
      OrderIcon,
      ServiceIcon,
      WalletIcon,
      WishlistIcon,
} from "../../icons";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";

const SideNavigation = () => {
      const [navigate, setNavigate] = useState(false);
      const login = localStorage.getItem("isLoggedIn");
      const [loading, setLoading] = useState(false);

      const userCtx = useContext(UserContext);

      async function onLogoutHandler() {
            setLoading(true);
            setTimeout(() => {
                  localStorage.clear();
                  toast.success("You are successfully logout");
                  setLoading(false);
                  window.location.reload();
            }, 3000);
            setNavigate(true);
      }

      if (userCtx?.user) {
            if (userCtx?.user.email_verified_at === null) {
                  return <Navigate to="/email/verify" />;
            }
      }

      if (login === null) {
            return <Navigate to="/login" />;
      }

      return (
            <>
                  <div className="dashboard__container-grid">
                        <div className="dashboard__navigation">
                              <NavLink
                                    className="dashboard__navigation-link dashboard__navigation-item"
                                    to="/dashboard"
                              >
                                    <DashboardIcon />
                                    Dashboard
                              </NavLink>
                              <NavLink
                                    className="dashboard__navigation-link dashboard__navigation-item"
                                    to="/wallet-refer"
                              >
                                    <WalletIcon />
                                    Earn & Refer
                              </NavLink>
                              <NavLink
                                    className="dashboard__navigation-link dashboard__navigation-item"
                                    to="/my-orders"
                              >
                                    <OrderIcon />
                                    My Orders
                              </NavLink>
                              <NavLink
                                    className="dashboard__navigation-link dashboard__navigation-item"
                                    to="/my-service"
                              >
                                    <ServiceIcon />
                                    My Service
                              </NavLink>
                              <NavLink
                                    className="dashboard__navigation-link dashboard__navigation-item"
                                    to="/wishlists"
                              >
                                    <WishlistIcon />
                                    My Wishlist
                              </NavLink>
                              <Link
                                    onClick={onLogoutHandler}
                                    className="dashboard__navigation-item dashboard__navigation-link"
                              >
                                    <LogoutIcon />
                                    Logout
                                    {loading ? (
                                          <Spinner
                                                style={{
                                                      width: "18px",
                                                      height: "18px",
                                                }}
                                          />
                                    ) : null}
                              </Link>
                        </div>
                  </div>
            </>
      );
};

export default SideNavigation;
