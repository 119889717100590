import styled from "styled-components";

export const SendButton = styled.button`
      .spinner-border {
            width: 13px;
            height: 13px;
            margin-left: 10px;
      }

      &:hover {
            color: rgba(232, 107, 25, 80%);
      }
`;
