import React, { useState } from "react";
import { Form, FormControl, FormLabel } from "react-bootstrap";
import { Col, Row, Spinner } from "reactstrap";

import $ from "jquery";
import axios from "axios";
import toast from "react-hot-toast";
import { SubmitButton } from "../../../../auth/style";

const VerificationCode = ({ email, setEmail, setStatus, setSlug }) => {
      const [loading, setLoading] = useState(false);

      async function handleTrackForm(event) {
            event.preventDefault();
            setLoading(true);
            $("button[type=submit]").prop("disabled", true);
            if (email === "") {
                  setTimeout(() => {
                        setLoading(false);
                        $("button[type=submit]").prop("disabled", false);
                        toast.error("Please enter your email address.");
                  }, 3000);
            } else {
                  const data = {
                        email: email,
                  };
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/customer/password/reset`,
                              data,
                              {
                                    headers: {
                                          apikey: process.env.REACT_APP_API_KEY,
                                    },
                              }
                        )
                        .then((response) => {
                              setTimeout(() => {
                                    setLoading(false);
                                    $("button[type=submit]").prop(
                                          "disabled",
                                          false
                                    );
                                    if (response.data.result === "success") {
                                          setStatus(true);
                                          setSlug(response.data.slug);
                                    }

                                    if (response.data.result === "error") {
                                          if (response.data.message) {
                                                toast.error(
                                                      response.data.message
                                                );
                                          }
                                    }
                              }, 3000);
                        })
                        .catch((error) => {
                              setTimeout(() => {
                                    setLoading(false);
                                    $("button[type=submit]").prop(
                                          "disabled",
                                          false
                                    );
                                    toast.error(error.message);
                              }, 3000);
                        });
            }
      }

      return (
            <>
                  <Form onSubmit={handleTrackForm}>
                        <Row className="g-3 align-items-center">
                              <Col lg={6} className="form-group">
                                    <FormLabel>Your Email Address</FormLabel>
                                    <FormControl
                                          type="email"
                                          value={email}
                                          autoFocus="email"
                                          onChange={(event) =>
                                                setEmail(event.target.value)
                                          }
                                    />
                              </Col>
                              <div>
                                    <SubmitButton type="submit">
                                          {loading ? <Spinner /> : "Continue"}
                                    </SubmitButton>
                              </div>
                        </Row>
                  </Form>
            </>
      );
};

export default VerificationCode;
