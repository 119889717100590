import React from 'react';
import { Link } from "react-router-dom";

const Coupon = ({ coupons, couponLength }) => {
      return (
            <>
                  <table className="table table-bordered table-hover">
                        <thead className="table-light text-uppercase">
                              <tr>
                                    <td>Id</td>
                                    <td>Order Time</td>
                                    <td>Method</td>
                                    <td>Status</td>
                                    <td>Total</td>
                                    <td>Action</td>
                              </tr>
                        </thead>
                        <tbody>
                              {(couponLength > 0) ? coupons.map((order, index) => (
                                    <tr key={index}>
                                          <td>{order.order_name}</td>
                                          <td>{order.created_date}</td>
                                          <td>{order.method}</td>
                                          <td>
                                                <span className={(order.status == 'Pending Order') ? 'text-warning' : 'text-success'}>{order.status}</span>
                                          </td>
                                          <td>Rs {order.total}</td>
                                          <td>
                                                <Link to={`/my-orders/${order.slug}`} className="bage bg-success px-2 rounded-pill text-white pb-1 btn-sm">Detail</Link>
                                          </td>
                                    </tr>
                              )) : (
                                    <tr>
                                          <td colSpan={6} className="text-center">No order found</td>
                                    </tr>
                              )}
                        </tbody>
                  </table>
            </>
      )
}

export default Coupon;