import React, { useState } from "react";

import { NumberFormatBase } from "react-number-format";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import ProductImage from "../products/ProductImage";
import RequestForm from "../requests/RequestForm";
import { Spinner } from "react-bootstrap";
import { QuoteButton } from "./style";

const Product = ({
      onrequest,
      index,
      image,
      name,
      offerPrice,
      unit,
      slug,
      price,
      handleAddWishlistSubmit,
      user,
}) => {
      const [show, setShow] = useState(false);
      const [loadingData, setLoadingData] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => {
            setLoadingData(true);
            setTimeout(() => {
                  setLoadingData(false);
                  setShow(true);
            }, 3000);
      };
      return (
            <>
                  <div className="product__item" key={index}>
                        <ProductImage
                              link={`/products/${slug}`}
                              name={name}
                              image={image}
                        />
                        {user !== null ? (
                              <div className="product__summary">
                                    <Link
                                          to={`/products/${slug}`}
                                          className="product__summary-details"
                                    >
                                          <h2
                                                className={`product__summary-title`}
                                          >
                                                <span className="line-clamp-2">
                                                      {name}
                                                </span>
                                          </h2>
                                    </Link>
                                    <div className="product__price-container">
                                          <div className="product__price-content">
                                                <span className="product__price">
                                                      {onrequest === true ? (
                                                            <button className="btn btn-success btn-sm">
                                                                  Quote
                                                            </button>
                                                      ) : offerPrice ? (
                                                            <NumberFormatBase
                                                                  displayType={
                                                                        "text"
                                                                  }
                                                                  thousandSeparator={
                                                                        true
                                                                  }
                                                                  thousandsGroupStyle="lakh"
                                                                  prefix={"Rs "}
                                                                  value={
                                                                        offerPrice
                                                                  }
                                                            />
                                                      ) : (
                                                            <>
                                                                  {price > 0 ? (
                                                                        <NumberFormatBase
                                                                              displayType={
                                                                                    "text"
                                                                              }
                                                                              thousandSeparator={
                                                                                    true
                                                                              }
                                                                              thousandsGroupStyle="lakh"
                                                                              prefix={
                                                                                    "Rs "
                                                                              }
                                                                              value={
                                                                                    price
                                                                              }
                                                                        />
                                                                  ) : (
                                                                        <>
                                                                              <QuoteButton
                                                                                    className="btn btn-success btn-sm"
                                                                                    onClick={
                                                                                          handleShow
                                                                                    }
                                                                              >
                                                                                    {loadingData ? (
                                                                                          <Spinner />
                                                                                    ) : (
                                                                                          "Quote"
                                                                                    )}
                                                                              </QuoteButton>
                                                                        </>
                                                                  )}
                                                            </>
                                                      )}
                                                </span>
                                          </div>
                                          <div className="d-flex">
                                                <button
                                                      aria-label="cart"
                                                      onClick={(e) =>
                                                            handleAddWishlistSubmit(
                                                                  slug,
                                                                  e
                                                            )
                                                      }
                                                      className="product__btn-container text-emerald-500"
                                                >
                                                      <i className="bx bx-heart"></i>
                                                </button>
                                          </div>
                                    </div>
                              </div>
                        ) : (
                              <div className="product__summary">
                                    <Link
                                          to={`/products/${slug}`}
                                          className="product__summary-details"
                                    >
                                          {name}
                                    </Link>
                              </div>
                        )}
                  </div>
                  <RequestForm
                        handleClose={handleClose}
                        show={show}
                        productId={slug}
                  />
            </>
      );
};

export default Product;
