import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SideNavigation from "../components/SideNavigation";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import "./style.css";
import Refer from "./Refer";
import Friend from "./Friend";
import Coupon from "./Coupon";
import toast from "react-hot-toast";
import UserContext from "../../data/UserContext";

const Wallet = () => {
      const [key, setKey] = useState("friends");
      const userCtx = useContext(UserContext);
      const user = JSON.parse(localStorage.getItem("userData"));

      const [coupons, setCoupons] = useState([]);
      const [friends, setFriends] = useState([]);
      const [couponLength, setCouponLength] = useState(0);
      const [friendLength, setFriendLength] = useState(0);

      const [orders, setOrders] = useState([]);
      const [ordersLength, setOrdersLength] = useState(0);

      const loadData = async () => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/user/myorders`,
                        { email: user ? user.email : "" }
                  )
                  .then((response) => {
                        if (response.data.result == "success") {
                              setOrders(response.data.orders);
                              setOrdersLength(response.data.orders.length);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="dashboard__container">
                        <div className="dashboard__content">
                              <SideNavigation />
                              <div className="dashboard__content-container">
                                    <div className="overflow-hidden">
                                          <h2 className="dashboard__content-title">
                                                Wallet Amount :{" "}
                                                <span className="text-emerald-500">
                                                      Rs.{" "}
                                                      {userCtx?.user
                                                            ? userCtx?.user
                                                                    .amount
                                                            : " "}
                                                </span>
                                          </h2>
                                          <Tabs
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => setKey(k)}
                                                className="mb-3"
                                          >
                                                <Tab
                                                      eventKey="friends"
                                                      title="Your Community"
                                                >
                                                      {friendLength > 0 ? (
                                                            <Friend
                                                                  friends={
                                                                        friends
                                                                  }
                                                                  friendLength={
                                                                        friendLength
                                                                  }
                                                            />
                                                      ) : (
                                                            <h5 className="text-center">
                                                                  No friends has
                                                                  been added
                                                            </h5>
                                                      )}
                                                </Tab>
                                                <Tab
                                                      eventKey="coupon"
                                                      title="Redeemed Coupon"
                                                >
                                                      {couponLength > 0 ? (
                                                            <Coupon
                                                                  coupons={
                                                                        coupons
                                                                  }
                                                                  couponLength={
                                                                        couponLength
                                                                  }
                                                            />
                                                      ) : (
                                                            <h5 className="text-center">
                                                                  No coupons
                                                                  redeemed
                                                            </h5>
                                                      )}
                                                </Tab>
                                          </Tabs>
                                          <Refer user={userCtx?.user} />
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Wallet;
