import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MetaContext from "../../data/MetaContext";

const Certificate = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("certificate");

      const [certificates, setCertificates] = useState([]);
      const [loading, setLoading] = useState(false);
      const [certificateLength, setCertificateLength] = useState(0);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/certificates`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCertificateLength(
                                    response.data.certificates.length
                              );
                              setCertificates(response.data.certificates);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta.seo_title || metaCtx.meta.title}
                  </h1>
                  <div className="category__body">
                        <div className="product__details-container">
                              <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                          <li className="breadcrumb-item">
                                                <a href="/">Home</a>
                                          </li>
                                          <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                          >
                                                Certificate
                                          </li>
                                    </ol>
                              </nav>

                              <div className="product__details-content">
                                    <div className="row">
                                          <div className="col-xs-12 ">
                                                <div className="row">
                                                      {certificateLength > 0 ? (
                                                            certificates?.map(
                                                                  (
                                                                        certificate,
                                                                        index
                                                                  ) => (
                                                                        <div
                                                                              className="col-md-3 col-sm-4 col-xs-12 col-tn position-relative"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <div>
                                                                                    <div className="product-item text-center p-0">
                                                                                          <div className="w-100">
                                                                                                <img
                                                                                                      src={
                                                                                                            certificate.image
                                                                                                      }
                                                                                                      className="img-responsive hover border p-3"
                                                                                                      style={{
                                                                                                            height: "300px",
                                                                                                      }}
                                                                                                      alt={
                                                                                                            certificate.title
                                                                                                      }
                                                                                                      referrerPolicy="no-referrer"
                                                                                                />
                                                                                          </div>
                                                                                          <div className="fs-5 w-100 text-center">
                                                                                                <p>
                                                                                                      {
                                                                                                            certificate.title
                                                                                                      }
                                                                                                </p>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  )
                                                            )
                                                      ) : (
                                                            <h4 className="text-center">
                                                                  Coming Soon
                                                            </h4>
                                                      )}
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Certificate;
