import React from "react";

const Refer = ({ user }) => {
      return (
            <>
                  <div className="mt-3 pt-3">
                        <h2 className="dashboard__content-title pb-2 border-bottom ">
                              Refer And Earn
                        </h2>
                        <p>
                              Enjoy cash rewards for every friend you invite to
                              the eSecurity Cart. Invite your friends and earn
                              an instant cash of Rs. 500. Your friend will also
                              enjoy a cash of 50% for verification. You will
                              earn Rs. 500 cash for every successful referral.
                              Redeem the new cash directly in your e-wallet.
                              Don't miss the chance!
                        </p>
                  </div>
                  <div className="border p-1 mt-3">
                        Your Referral URL :
                        https://www.esecuritycart.com?referral_code=
                        {user ? user?.referral_code : ""}
                  </div>
            </>
      );
};

export default Refer;
