import React from "react";
import { Col, FormControl, FormLabel } from "react-bootstrap";

const SingleForm = ({
      setFullName,
      setEmailAddress,
      setPhoneNumber,
      setAddress,
      fullName,
      emailAddress,
      phoneNumber,
      address,
}) => {
      return (
            <>
                  <Col lg={6}>
                        <FormLabel>
                              Full Name <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl
                              type="text"
                              value={fullName}
                              onChange={(event) =>
                                    setFullName(event.target.value)
                              }
                        />
                  </Col>
                  <Col lg={6}>
                        <FormLabel>
                              Email Address{" "}
                              <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl
                              type="email"
                              value={emailAddress}
                              onChange={(event) =>
                                    setEmailAddress(event.target.value)
                              }
                        />
                  </Col>
                  <Col lg={6}>
                        <FormLabel>
                              Phone Number{" "}
                              <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl
                              type="text"
                              value={phoneNumber}
                              onChange={(event) =>
                                    setPhoneNumber(event.target.value)
                              }
                        />
                  </Col>
                  <Col lg={6}>
                        <FormLabel>
                              Your Address{" "}
                              <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl
                              type="text"
                              value={address}
                              onChange={(event) =>
                                    setAddress(event.target.value)
                              }
                        />
                  </Col>
            </>
      );
};

export default SingleForm;
