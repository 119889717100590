import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../blog/style.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const TrainingDetail = () => {
      const { trainingId } = useParams();
      const [training, setTraining] = useState([]);
      const [loading, setLoading] = useState(false);
      const [relatedTrainings, setRelatedTrainings] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/trainings/${trainingId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setTraining(response.data.training);
                        setRelatedTrainings(response.data.trainings);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [trainingId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>{training?.title}</title>
                              {training?.seo_description && (
                                    <meta
                                          name="description"
                                          content={training?.seo_description}
                                    />
                              )}
                              {training?.seo_title && (
                                    <meta
                                          name="keyword"
                                          content={training?.seo_title}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <h1 className="d-none">
                        {training?.seo_title || training?.title}
                  </h1>
                  <div className="container pt-3">
                        <nav
                              aria-label="breadcrumb"
                              className="d-none d-lg-block"
                        >
                              <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                          <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                          <Link to="/trainings">
                                                Training List
                                          </Link>
                                    </li>
                                    <li
                                          className="breadcrumb-item active"
                                          aria-current="page"
                                    >
                                          {training.title}
                                    </li>
                              </ol>
                        </nav>
                  </div>
                  <section className="blog-standard-section pt-3 pb-5">
                        <div className="container">
                              <div className="row gy-5">
                                    <div className="col col-md-8 bg-white">
                                          <div className="blog-details-single">
                                                <div className="post format-standard-image">
                                                      <div className="image">
                                                            <img
                                                                  src={
                                                                        training.image
                                                                  }
                                                                  style={{
                                                                        width: "100%",
                                                                        height: "410px",
                                                                  }}
                                                                  alt={
                                                                        training.title
                                                                  }
                                                                  referrerPolicy="no-referrer"
                                                            />
                                                      </div>
                                                      <div className="text">
                                                            <h2>
                                                                  {
                                                                        training.title
                                                                  }
                                                            </h2>
                                                            <ul className="post-meta-list mt-0">
                                                                  <li>
                                                                        <img
                                                                              src="/images/icons/user.svg"
                                                                              alt="User"
                                                                              referrerPolicy="no-referrer"
                                                                        />
                                                                        <span>
                                                                              By:
                                                                              Admin{" "}
                                                                        </span>
                                                                  </li>
                                                                  <li>
                                                                        <img
                                                                              src="/images/icons/calendr.svg"
                                                                              alt="Calendar"
                                                                              referrerPolicy="no-referrer"
                                                                        />
                                                                        <span>
                                                                              Date:{" "}
                                                                              {
                                                                                    training.date
                                                                              }{" "}
                                                                        </span>
                                                                  </li>
                                                            </ul>
                                                      </div>
                                                      <div
                                                            dangerouslySetInnerHTML={{
                                                                  __html: training.description,
                                                            }}
                                                      ></div>
                                                </div>
                                                <div className="blog-sharea-area mt-3">
                                                      <div className="row align-items-center g-4">
                                                            <div className="col-lg-12 d-flex-end justify-content-md-end justify-content-center">
                                                                  <div className="blog-share">
                                                                        <div className="front">
                                                                              <span>
                                                                                    Share
                                                                                    Now
                                                                              </span>
                                                                        </div>
                                                                        <div className="back">
                                                                              <ul className="share-list d-flex flex-row justify-content-end align-items-end gap-3">
                                                                                    <li>
                                                                                          <a href="https://www.facebook.com/">
                                                                                                <i className="fa-brands fa-facebook-f"></i>
                                                                                          </a>
                                                                                    </li>
                                                                                    <li>
                                                                                          <a href="https://www.twitter.com/">
                                                                                                <i className="fa-brands fa-twitter"></i>
                                                                                          </a>
                                                                                    </li>
                                                                                    <li>
                                                                                          <a href="https://www.instagram.com/">
                                                                                                <i className="fa-brands fa-instagram"></i>
                                                                                          </a>
                                                                                    </li>
                                                                                    <li>
                                                                                          <a href="https://www.pinterest.com/">
                                                                                                <i className="fa-brands fa-pinterest"></i>
                                                                                          </a>
                                                                                    </li>
                                                                              </ul>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                                    <Sidebar
                                          relatedTrainings={relatedTrainings}
                                    />
                              </div>
                        </div>
                  </section>
            </>
      );
};

export default TrainingDetail;
