import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import React, { useState, useEffect, useContext } from "react";
import MetaContext from "../../data/MetaContext";
import { Col, Row } from "reactstrap";

const FaqList = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("faqs");

      const [faqs, setFaqs] = useState([]);
      const [faqLength, setFaqLength] = useState(0);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setFaqLength(response.data.faqs.length);
                              setFaqs(response.data.faqs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta.seo_title || metaCtx.meta.title}
                  </h1>
                  <div className="category__body">
                        <div className="product__details-container">
                              <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                          <li className="breadcrumb-item">
                                                <a href="/">Home</a>
                                          </li>
                                          <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                          >
                                                Frequently Asked Question
                                          </li>
                                    </ol>
                              </nav>
                              <Row className="justify-content-center">
                                    <Col lg={8}>
                                          <Accordion defaultActiveKey="0">
                                                {faqLength > 0 &&
                                                      faqs.map((faq, index) => (
                                                            <Accordion.Item
                                                                  eventKey={`${index}`}
                                                            >
                                                                  <Accordion.Header>
                                                                        {
                                                                              faq.question
                                                                        }
                                                                  </Accordion.Header>
                                                                  <Accordion.Body>
                                                                        {
                                                                              faq.answer
                                                                        }
                                                                  </Accordion.Body>
                                                            </Accordion.Item>
                                                      ))}
                                          </Accordion>
                                    </Col>
                              </Row>
                        </div>
                  </div>
            </>
      );
};

export default FaqList;
