import React from "react";

import PriceSlider from "../../components/frontend/filters/PriceSlider";
import RelatedBrand from "../../components/frontend/filters/RelatedBrand";
import RelatedCategory from "../../components/frontend/filters/RelatedCategory";
import RelatedSize from "../../components/frontend/filters/RelatedSize";

const FilterDesktop = ({
      loading,
      categories,
      brands,
      sizes,
      handleChangeBrand,
      selectedPrice,
      handleChangePrice,
      handleChangeCategory,
      maxAmount,
      handleChangeSizes,
}) => {
      return (
            <>
                  <div className="slider__content d-none d-lg-block ">
                        <div>
                              <div className="w-100">
                                    <div className="slider__category">
                                          <h2 className="offcanvas__body-title">
                                                Related Category
                                          </h2>
                                          <div className="slider__category-container">
                                                <div className="slider__category-content">
                                                      <div className="slider__category-body">
                                                            <div
                                                                  className="slider__category-grid"
                                                                  style={{
                                                                        maxHeight:
                                                                              "286px",
                                                                        overflowX:
                                                                              "hidden",
                                                                        overflowY:
                                                                              "auto",
                                                                  }}
                                                            >
                                                                  <RelatedCategory
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        categories={
                                                                              categories
                                                                        }
                                                                        handleChangeCategory={
                                                                              handleChangeCategory
                                                                        }
                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>

                              <div className="w-100 mt-3">
                                    <div className="slider__category">
                                          <h2 className="offcanvas__body-title">
                                                Brand
                                          </h2>
                                          <div className="slider__category-container">
                                                <div className="slider__category-content">
                                                      <div className="slider__category-body">
                                                            <div
                                                                  className="slider__category-grid"
                                                                  style={{
                                                                        maxHeight:
                                                                              "286px",
                                                                        overflowX:
                                                                              "hidden",
                                                                        overflowY:
                                                                              "auto",
                                                                  }}
                                                            >
                                                                  <RelatedBrand
                                                                        brands={
                                                                              brands
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        changeChecked={
                                                                              handleChangeBrand
                                                                        }
                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>

                              <div className="w-100 mt-3">
                                    <div className="slider__category">
                                          <h2 className="offcanvas__body-title">
                                                Sizes
                                          </h2>
                                          <div className="slider__category-container">
                                                <div className="slider__category-content">
                                                      <div className="slider__category-body">
                                                            <div
                                                                  className="slider__category-grid"
                                                                  style={{
                                                                        maxHeight:
                                                                              "286px",
                                                                        overflowX:
                                                                              "hidden",
                                                                        overflowY:
                                                                              "auto",
                                                                  }}
                                                            >
                                                                  <RelatedSize
                                                                        sizes={
                                                                              sizes
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        handleChangeSizes={
                                                                              handleChangeSizes
                                                                        }
                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>

                              {/* <div className="w-100 mt-3">
                        <div className="slider__category">
                            <h2 className="offcanvas__body-title">Price</h2>
                            <div className="slider__category-container mt-5">
                                <div className="slider__category-content">
                                    <div className="slider__category-body">
                                        <div className="slider__category-grid">
                                            <PriceSlider value={selectedPrice} maxAmount={maxAmount} changePrice={handleChangePrice} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        </div>
                  </div>
            </>
      );
};

export default FilterDesktop;
