import React, { useContext, useState } from "react";
import MetaContext from "../../data/MetaContext";
import {
      Col,
      Container,
      Form,
      FormControl,
      FormSelect,
      Row,
      Spinner,
} from "react-bootstrap";

import $ from "jquery";
import axios from "axios";
import toast from "react-hot-toast";
import { WasteContent } from "./style";

const Waste = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("e-waste");

      const [loading, setLoading] = useState("");
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [mobileNumber, setMobileNumber] = useState("");
      const [state, setState] = useState("");
      const [city, setCity] = useState("");
      const [address, setAddress] = useState("");
      const [landmark, setLandmark] = useState("");
      const [productName, setProductName] = useState("");
      const [serialNumber, setSerialNumber] = useState("");
      const [purchaseDate, setPurchaseDate] = useState("");
      const [billingCard, setBillingCard] = useState("");
      const [remarks, setRemarks] = useState("");

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  name: name,
                  email: email,
                  state: state,
                  mobile_number: mobileNumber,
                  city: city,
                  address: address,
                  landmark: landmark,
                  product_name: productName,
                  serial_number: serialNumber,
                  purchase_date: purchaseDate,
                  billing_card: billingCard,
                  remarks: remarks,
            };

            await axios
                  .post(`${process.env.REACT_APP_SECRET_KEY}/api/waste`, data, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                              "Content-Type": "multipart/form-data",
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTimeout(() => {
                                    $("form").trigger("reset");
                                    setName("");
                                    setEmail("");
                                    setMobileNumber("");
                                    setState("");
                                    setCity("");
                                    setAddress("");
                                    setLandmark("");
                                    setProductName("");
                                    setSerialNumber("");
                                    setPurchaseDate("");
                                    setBillingCard("");
                                    setRemarks("");
                                    toast.success(response.data.message);
                                    setLoading(false);
                              }, 3000);
                        }
                        if (response.data.result === "error") {
                              setTimeout(() => {
                                    $("form").trigger("reset");
                                    setLoading(false);
                              }, 3000);
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.state) {
                                    toast.error(response.data.message.state);
                              }
                              if (response.data.message.mobile_number) {
                                    toast.error(
                                          response.data.message.mobile_number
                                    );
                              }
                              if (response.data.message.city) {
                                    toast.error(response.data.message.city);
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.landmark) {
                                    toast.error(response.data.message.landmark);
                              }
                              if (response.data.message.product_name) {
                                    toast.error(
                                          response.data.message.product_name
                                    );
                              }
                              if (response.data.message.serial_number) {
                                    toast.error(
                                          response.data.message.serial_number
                                    );
                              }
                              if (response.data.message.purchase_date) {
                                    toast.error(
                                          response.data.message.purchase_date
                                    );
                              }
                              if (response.data.message.billing_card) {
                                    toast.error(
                                          response.data.message.billing_card
                                    );
                              }
                              if (response.data.message.remarks) {
                                    toast.error(response.data.message.remarks);
                              }
                        }
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                        }, 3000);
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <div className="about__header-container banner">
                        <div className="about__header-content">
                              <div className="about__header-summary">
                                    <h1 className="about__header-title text-white">
                                          E Waste
                                    </h1>
                              </div>
                        </div>
                  </div>
                  <Container>
                        <Row>
                              <Col lg={12}>
                                    <WasteContent>
                                          <Form onSubmit={handleForm}>
                                                <Row className="g-3">
                                                      <Col xs={12}>
                                                            <p className="fs-4 m-0 border-bottom mt-3 pb-2">
                                                                  Personal
                                                                  Information
                                                            </p>
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Name{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>{" "}
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={name}
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setName(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Email ID{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="email"
                                                                  value={email}
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setEmail(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Mobile Number{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={
                                                                        mobileNumber
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setMobileNumber(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>

                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  State{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormSelect
                                                                  value={state}
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setState(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            >
                                                                  <option value="">
                                                                        --Select
                                                                        State/Province--
                                                                  </option>
                                                                  <option value="Province 1">
                                                                        Province
                                                                        1
                                                                  </option>
                                                                  <option value="Province 2">
                                                                        Province
                                                                        2
                                                                  </option>
                                                                  <option value="Province 3">
                                                                        Province
                                                                        3
                                                                  </option>
                                                                  <option value="Province 4">
                                                                        Province
                                                                        4
                                                                  </option>
                                                                  <option value="Province 5">
                                                                        Province
                                                                        5
                                                                  </option>
                                                                  <option value="Province 6">
                                                                        Province
                                                                        6
                                                                  </option>
                                                                  <option value="Province 7">
                                                                        Province
                                                                        7
                                                                  </option>
                                                            </FormSelect>
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  City{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormControl
                                                                  value={city}
                                                                  type="text"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setCity(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Address{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={
                                                                        address
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setAddress(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Landmark
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={
                                                                        landmark
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setLandmark(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col xs={12}>
                                                            <p className="fs-4 m-0 pb-2 border-bottom mt-3">
                                                                  Product
                                                                  Information
                                                            </p>
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Product Name{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={
                                                                        productName
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setProductName(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Product Serial
                                                                  No.
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={
                                                                        serialNumber
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setSerialNumber(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Purchase Date{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="date"
                                                                  value={
                                                                        purchaseDate
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setPurchaseDate(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col xs={12}>
                                                            <p className="fs-4 m-0 mt-3 border-bottom pb-2">
                                                                  Product
                                                                  Verification
                                                            </p>
                                                      </Col>
                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Billing Card
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="file"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setBillingCard(
                                                                              event
                                                                                    .target
                                                                                    .files[0]
                                                                        )
                                                                  }
                                                            />
                                                      </Col>

                                                      <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={3}
                                                      >
                                                            <Form.Label>
                                                                  Remark
                                                            </Form.Label>
                                                            <FormControl
                                                                  type="text"
                                                                  value={
                                                                        remarks
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setRemarks(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                                <div className="text-left mt-4 mb-4">
                                                      <button
                                                            className="contact__form-btn bg-emerald-500"
                                                            type="submit"
                                                      >
                                                            {loading ? (
                                                                  <Spinner />
                                                            ) : (
                                                                  "Submit"
                                                            )}
                                                      </button>
                                                </div>
                                          </Form>
                                    </WasteContent>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default Waste;
