import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import { Navigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import {
      VerificationCode,
      EmailVerify,
      ResetPassword,
} from "../../components/frontend/forget-password";

const ForgetPassword = () => {
      const [status, setStatus] = useState(false);
      const [verifyStatus, setVerifyStatus] = useState(false);
      const [email, setEmail] = useState("");
      const [slug, setSlug] = useState(false);

      const login = localStorage.getItem("isLoggedIn");
      if (login) {
            return <Navigate to="/" />;
      }

      return (
            <>
                  <div className="category__body pt-5 pb-5">
                        <div className="category__content">
                              <div className="category__content-body">
                                    <Row className="justify-content-center">
                                          <Col lg={6}>
                                                <article className="card tracking__card overflow-hidden">
                                                      <header className="tracking__card-header">
                                                            {" "}
                                                            Reset Your Password{" "}
                                                      </header>
                                                      {status ? (
                                                            verifyStatus ? (
                                                                  <>
                                                                        <ResetPassword
                                                                              slug={
                                                                                    slug
                                                                              }
                                                                        />
                                                                  </>
                                                            ) : (
                                                                  <Fade
                                                                        right={
                                                                              true
                                                                        }
                                                                        duration="2000"
                                                                  >
                                                                        <EmailVerify
                                                                              slug={
                                                                                    slug
                                                                              }
                                                                              setSlug={
                                                                                    setSlug
                                                                              }
                                                                              email={
                                                                                    email
                                                                              }
                                                                              setVerifyStatus={
                                                                                    setVerifyStatus
                                                                              }
                                                                        />
                                                                  </Fade>
                                                            )
                                                      ) : (
                                                            <div>
                                                                  <VerificationCode
                                                                        email={
                                                                              email
                                                                        }
                                                                        setEmail={
                                                                              setEmail
                                                                        }
                                                                        status={
                                                                              status
                                                                        }
                                                                        setStatus={
                                                                              setStatus
                                                                        }
                                                                        setSlug={
                                                                              setSlug
                                                                        }
                                                                  />
                                                            </div>
                                                      )}
                                                </article>
                                          </Col>
                                    </Row>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ForgetPassword;
