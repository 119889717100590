import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import toast from "react-hot-toast";
import { NumberFormatBase } from "react-number-format";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Loading from "../../../layouts/Loading";
import CartContext from "../../../data/cart-context";
import { Helmet } from "react-helmet";

const BrandProduct = () => {
      const cartCtx = useContext(CartContext);
      const login = localStorage.getItem("isLoggedIn");
      const user = JSON.parse(localStorage.getItem("userData"));

      const { brandId } = useParams();
      const [products, setProducts] = useState([]);
      const [brand, setBrand] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/brands/${brandId}/products`,
                  {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  }
            )
                  .then((response) => {
                        setBrand(response.data.brand);
                        setProducts(response.data.products);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      function handleAddCartSubmit(slug, e) {
            if (login) {
                  cartCtx.addCartSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      function handleAddWishlistSubmit(slug, e) {
            if (login) {
                  cartCtx.addWishlistSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      if (loading)
            return (
                  <>
                        <Loading />
                  </>
            );

      return (
            <>
                  <Helmet>
                        <title>{brand?.seo_title || brand?.title}</title>
                        {brand?.seo_description && (
                              <meta
                                    name="description"
                                    content={brand?.seo_description}
                              />
                        )}
                        {brand?.seo_keyword && (
                              <meta
                                    name="keyword"
                                    content={brand?.seo_keyword}
                              />
                        )}
                        <link rel="canonical" href={window.location.href} />
                  </Helmet>
                  <h1 className="d-none">{brand?.seo_title || brand?.title}</h1>

                  <div className="product__details">
                        <div className="product__details-container">
                              <div className="d-flex w-100">
                                    <div className="w-100">
                                          <div className="product__details-content">
                                                <div className="review__row">
                                                      <h2 className="review__row-title py-2">
                                                            <span className="d-block d-lg-block">
                                                                  {
                                                                        products.length
                                                                  }{" "}
                                                                  items found in{" "}
                                                                  {brand.title}
                                                            </span>
                                                      </h2>
                                                </div>
                                          </div>
                                          <div className="product__content mt-3">
                                                {!loading ? (
                                                      products.map(
                                                            (
                                                                  product,
                                                                  index
                                                            ) => (
                                                                  <div
                                                                        className="product__item"
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <a
                                                                              href={`/products/${product.slug}`}
                                                                              className="product__item-image"
                                                                        >
                                                                              <span className="product__image-container">
                                                                                    <span className="product__image-content">
                                                                                          <LazyLoadImage
                                                                                                className="product__image"
                                                                                                effect="blur"
                                                                                                alt={
                                                                                                      product.title
                                                                                                }
                                                                                                src={
                                                                                                      product.image
                                                                                                }
                                                                                                referrerPolicy="no-referrer"
                                                                                          />
                                                                                    </span>
                                                                              </span>
                                                                        </a>
                                                                        {user !==
                                                                        null ? (
                                                                              <div className="product__summary">
                                                                                    <a
                                                                                          href={`/products/${product.slug}`}
                                                                                          className="product__summary-details"
                                                                                    >
                                                                                          <h2
                                                                                                className={`product__summary-title ${
                                                                                                      product.unit
                                                                                                            ? ""
                                                                                                            : "mt-2"
                                                                                                }`}
                                                                                          >
                                                                                                <span className="line-clamp-2">
                                                                                                      {
                                                                                                            product.title
                                                                                                      }
                                                                                                </span>
                                                                                          </h2>
                                                                                    </a>
                                                                                    <div className="product__price-container">
                                                                                          <a
                                                                                                href={`/products/${product.slug}`}
                                                                                                className="product__price-content"
                                                                                          >
                                                                                                <span className="product__price">
                                                                                                      {product.offer_price ? (
                                                                                                            <NumberFormatBase
                                                                                                                  displayType={
                                                                                                                        "text"
                                                                                                                  }
                                                                                                                  thousandSeparator={
                                                                                                                        true
                                                                                                                  }
                                                                                                                  thousandsGroupStyle="lakh"
                                                                                                                  prefix={
                                                                                                                        "Rs "
                                                                                                                  }
                                                                                                                  value={
                                                                                                                        product.offer_price
                                                                                                                  }
                                                                                                            />
                                                                                                      ) : (
                                                                                                            <>
                                                                                                                  {product.price >
                                                                                                                  0 ? (
                                                                                                                        <NumberFormatBase
                                                                                                                              displayType={
                                                                                                                                    "text"
                                                                                                                              }
                                                                                                                              thousandSeparator={
                                                                                                                                    true
                                                                                                                              }
                                                                                                                              thousandsGroupStyle="lakh"
                                                                                                                              prefix={
                                                                                                                                    "Rs "
                                                                                                                              }
                                                                                                                              value={
                                                                                                                                    product.price
                                                                                                                              }
                                                                                                                        />
                                                                                                                  ) : (
                                                                                                                        <>
                                                                                                                              <button className="btn btn-success btn-sm">
                                                                                                                                    Quote
                                                                                                                              </button>
                                                                                                                        </>
                                                                                                                  )}
                                                                                                            </>
                                                                                                      )}
                                                                                                </span>
                                                                                          </a>
                                                                                          <div className="d-flex">
                                                                                                <button
                                                                                                      aria-label="cart"
                                                                                                      onClick={(
                                                                                                            e
                                                                                                      ) =>
                                                                                                            handleAddWishlistSubmit(
                                                                                                                  product.slug,
                                                                                                                  e
                                                                                                            )
                                                                                                      }
                                                                                                      className="product__btn-container text-emerald-500"
                                                                                                >
                                                                                                      <i className="bx bx-heart"></i>
                                                                                                </button>
                                                                                                {/* <button aria-label="cart" onClick={(e) => handleAddCartSubmit(product.slug, e)} className="product__btn-container text-emerald-500">
                                                        <i className="fa-light fa-cart-shopping"></i>
                                                    </button> */}
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        ) : (
                                                                              <div className="product__summary">
                                                                                    <Link
                                                                                          to={`/products/${product.slug}`}
                                                                                          className="product__summary-details"
                                                                                    >
                                                                                          {
                                                                                                product.title
                                                                                          }
                                                                                    </Link>
                                                                              </div>
                                                                        )}
                                                                  </div>
                                                            )
                                                      )
                                                ) : (
                                                      <>
                                                            <div className="loading">
                                                                  loading
                                                            </div>
                                                      </>
                                                )}
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BrandProduct;
