import axios from "axios";
import $ from "jquery";
import React, { useContext, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, Navigate } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";

import CartContext from "../../data/cart-context";

const Checkout = () => {
      const cartCtx = useContext(CartContext);
      const [userDetail, setUserDetail] = useState([]);

      const login = localStorage.getItem("isLoggedIn");
      const [isLoading, setIsLoading] = useState(false);
      const user = JSON.parse(localStorage.getItem("userData"));
      const [redirect, setRedirect] = useState(false);

      const [company, setCompany] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [state, setState] = useState("");
      const [city, setCity] = useState("");
      const [street, setStreet] = useState("");
      const [country, setCountry] = useState("");
      const [postal, setPostal] = useState("");
      const [payment, setPayment] = useState("");

      const [walletApply, setWalletApply] = useState(true);
      const [couponApply, setCouponApply] = useState(true);
      const [totalAmount, setTotalAmount] = useState(0);
      const [walletAmount, setWalletAmount] = useState(0);
      const [couponCode, setCouponCode] = useState(null);
      const [couponAmount, setCouponAmount] = useState(0);

      useEffect(() => {
            axios.post(
                  `${process.env.REACT_APP_SECRET_KEY}/api/user/address/check`,
                  { email: user ? user.email : "" }
            ).then((response) => {
                  if (response.data.result == "success") {
                        setUserDetail(response.data.shipping);
                        setPhoneNumber(response.data.shipping.phone);
                        setState(response.data.shipping.state);
                        setCity(response.data.shipping.city);
                        setStreet(response.data.shipping.street);
                        setPostal(response.data.shipping.postal);
                        setCountry(response.data.shipping.country);
                  }
            });
      }, []);

      useEffect(() => {
            setTotalAmount(cartCtx.totalAmount);
      }, [cartCtx.totalAmount]);

      function handleForm(event) {
            setIsLoading(true);
            event.preventDefault();
            const data = {
                  full_name: user.name,
                  company: company,
                  email: user.email,
                  phone: phoneNumber,
                  state: state,
                  city: city,
                  street: street,
                  country: country,
                  postal: postal,
                  payment: payment,
                  shop_code: 22280,
                  wallet_amount: walletAmount,
                  coupon_code: couponCode,
                  coupon_amount: couponAmount,
                  total_amount: totalAmount,
            };
            axios.post(
                  `${process.env.REACT_APP_SECRET_KEY}/api/checkout/update`,
                  data
            ).then((response) => {
                  if (response.data.result === "success") {
                        $("form").trigger("reset");
                        toast.success(response.data.message);
                        setRedirect(true);
                  }
                  if (response.data.result === "error") {
                        if (response.data.errorMessage) {
                              toast.error(response.data.errorMessage);
                        }
                        if (response.data.message["phone"]) {
                              toast.error(response.data.message["phone"]);
                        }
                        if (response.data.message["street"]) {
                              toast.error(response.data.message["street"]);
                        }
                        if (response.data.message["full_name"]) {
                              toast.error(response.data.message["full_name"]);
                        }
                        if (response.data.message["city"]) {
                              toast.error(response.data.message["city"]);
                        }
                        if (response.data.message["country"]) {
                              toast.error(response.data.message["country"]);
                        }
                        if (response.data.message["postal"]) {
                              toast.error(response.data.message["postal"]);
                        }
                        if (response.data.message["payment"]) {
                              toast.error(response.data.message["payment"]);
                        }
                        if (response.data.message["state"]) {
                              toast.error(response.data.message["state"]);
                        }
                        if (response.data.message[8]) {
                              toast.error(response.data.message[8]);
                        }
                        if (response.data.message[9]) {
                              toast.error(response.data.message[9]);
                        }
                        if (response.data.message[10]) {
                              toast.error(response.data.message[10]);
                        }
                  }
            });
            setIsLoading(false);
      }

      useEffect(() => {
            setWalletAmount(walletAmount);
      }, [walletAmount]);

      function handleWallet(event) {
            event.preventDefault();
            const data = {
                  email: user.email,
                  totalAmount: totalAmount,
            };
            if (walletApply) {
                  axios.post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/wallets/apply`,
                        data
                  ).then((response) => {
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                        if (response.data.result === "success") {
                              setTotalAmount(response.data.totalAmount);
                              setWalletAmount(response.data.walletAmount);
                              setWalletApply(false);
                        }
                  });
            } else {
                  toast.error("Already applied");
            }
      }

      function handleCoupon(event) {
            event.preventDefault();
            const data = {
                  coupon_code: couponCode,
                  totalAmount: totalAmount,
                  email: user.email,
            };

            if (couponApply) {
                  axios.post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/coupons/apply`,
                        data
                  ).then((response) => {
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                        if (response.data.result === "success") {
                              setTotalAmount(response.data.totalAmount);
                              setCouponAmount(response.data.couponAmount);
                              setCouponApply(false);
                        }
                  });
            } else {
                  toast.error("Already applied");
            }
      }

      if (redirect) {
            return <Navigate to="/my-orders" />;
      }

      if (login == null) {
            return <Navigate to="/login" push={true} />;
      }

      return (
            <>
                  <div className="cart__container">
                        <div className="cart__container-content">
                              <div className="cart__container-body">
                                    <div className="cart__form">
                                          <CheckoutForm
                                                handleForm={handleForm}
                                                user={user}
                                                userDetail={userDetail}
                                                setCompany={setCompany}
                                                setPhoneNumber={setPhoneNumber}
                                                setState={setState}
                                                setCity={setCity}
                                                setStreet={setStreet}
                                                setCountry={setCountry}
                                                isLoading={isLoading}
                                                setPayment={setPayment}
                                                setPostal={setPostal}
                                          />
                                    </div>
                              </div>
                              <div className="shoping__container">
                                    <div className="shoping__summary">
                                          <div className="d-flex align-items-center py-4 text-sm w-full font-semibold text-heading">
                                                <form
                                                      onSubmit={handleWallet}
                                                      className="w-100"
                                                >
                                                      <div className="shoping__coupon">
                                                            <select className="form-select">
                                                                  <option>
                                                                        Pay from
                                                                        wallet
                                                                  </option>
                                                                  <option
                                                                        value={
                                                                              user.amount
                                                                        }
                                                                  >
                                                                        Rs{" "}
                                                                        {
                                                                              user.amount
                                                                        }
                                                                  </option>
                                                            </select>
                                                            <button
                                                                  style={{
                                                                        height: "40px",
                                                                  }}
                                                                  type="submit"
                                                                  className="shoping__coupon-btn shoping__wallet"
                                                            >
                                                                  Apply
                                                            </button>
                                                      </div>
                                                </form>
                                          </div>
                                          <h2 className="font-semibold font-serif text-lg">
                                                Order Summary
                                          </h2>
                                          <div className="shoping__amount">
                                                Total item(s)
                                                <span className="shoping__price">
                                                      {cartCtx.totalCart}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                Subtotal (Inc. TAX)
                                                <span className="shoping__price">
                                                      Rs {cartCtx.cartSubTotal}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                eSecurity Offer Discount
                                                <span className="shoping__price shoping__discount">
                                                      Rs{" "}
                                                      {cartCtx.discountAmount}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                Cashback
                                                <span className="shoping__price">
                                                      Rs{" "}
                                                      {cartCtx.cashbackAmount}
                                                </span>
                                          </div>
                                          <div className="shoping__amount">
                                                Shipping Cost
                                                <span className="shoping__price">
                                                      Rs 0.00
                                                </span>
                                          </div>
                                          {walletAmount > 0 && (
                                                <div className="shoping__amount">
                                                      Wallet Discount
                                                      <span className="shoping__price">
                                                            Rs {walletAmount}
                                                      </span>
                                                </div>
                                          )}
                                          {couponAmount > 0 && (
                                                <div className="shoping__amount">
                                                      Coupon Discount
                                                      <span className="shoping__price">
                                                            Rs {couponAmount}
                                                      </span>
                                                </div>
                                          )}

                                          <div className="d-flex align-items-center py-4 text-sm w-full font-semibold text-heading">
                                                <form
                                                      onSubmit={handleCoupon}
                                                      className="w-100"
                                                >
                                                      <div className="shoping__coupon">
                                                            <input
                                                                  type="text"
                                                                  placeholder="Enput your coupon code"
                                                                  className="shoping__coupon-input"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setCouponCode(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                            <button
                                                                  className="shoping__coupon-btn"
                                                                  type="submit"
                                                            >
                                                                  Apply
                                                            </button>
                                                      </div>
                                                </form>
                                          </div>
                                          <div className="shoping__hr">
                                                <div className="shoping__total">
                                                      Total cost
                                                      <span className="font-serif font-extrabold text-lg text-capitalize">
                                                            {" "}
                                                            Rs {totalAmount}
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Checkout;
