import React, { useState } from "react";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Spinner } from "reactstrap";
import { SubmitButton } from "../../../../auth/style";
import axios from "axios";
import $ from "jquery";
import toast from "react-hot-toast";

const ResetPassword = ({slug}) => {
      const [password, setPassword] = useState("");
      const [confirmationPassword, setConfirmationPassword] = useState("");
      const [loading, setLoading] = useState(false);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true)

            const data = {
                  password: password,
                  password_confirmation: confirmationPassword,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/password/${slug}/change`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    setPassword("");
                                    setConfirmationPassword("")
                                    toast.success(response.data.message);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message.password) {
                                          toast.error(
                                                response.data.message.password
                                          );
                                    }
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                              }
                              setLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              toast.error(error.message);
                        }, 3000);
                  });
      };

      return (
            <>
                  <Fade>
                        <Form onSubmit={handleSubmit}>
                              <Row>
                                    <Col lg={8}>
                                          <FormLabel>New password</FormLabel>
                                          <FormControl
                                                type="password"
                                                onChange={(event) =>
                                                      setPassword(
                                                            event.target.value
                                                      )
                                                }
                                                value={password}
                                          />
                                    </Col>
                                    <Col lg={8}>
                                          <FormLabel>
                                                Confirm password
                                          </FormLabel>
                                          <FormControl
                                                type="password"
                                                onChange={(event) =>
                                                      setConfirmationPassword(
                                                            event.target.value
                                                      )
                                                }
                                                value={confirmationPassword}
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          <SubmitButton type="submit">
                                                {loading ? (
                                                      <Spinner />
                                                ) : (
                                                      "Submit"
                                                )}
                                          </SubmitButton>
                                    </Col>
                              </Row>
                        </Form>
                  </Fade>
            </>
      );
};

export default ResetPassword;
