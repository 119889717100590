import React from "react";
import { Spinner } from "react-bootstrap";

const FormButton = ({ classes, title, handleChange, loading }) => {
      return (
            <>
                  <button
                        onClick={handleChange}
                        className={`product__cart bg-emerald-500 hover:bg-emerald-600 ${classes}`}
                  >
                        {title} {loading && <Spinner />}
                  </button>
            </>
      );
};

export default FormButton;
