import axios from "axios";
import React, { useEffect, useState } from "react";
import SideNavigation from "../components/SideNavigation";

import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

const Order = () => {
      const user = JSON.parse(localStorage.getItem("userData"));

      const [orders, setOrders] = useState([]);
      const [ordersLength, setOrdersLength] = useState(0);

      useEffect(() => {
            axios.post(
                  `${process.env.REACT_APP_SECRET_KEY}/api/user/myorders`,
                  { email: user ? user.email : "" }
            ).then((response) => {
                  if (response.data.result == "success") {
                        setOrders(response.data.orders);
                        setOrdersLength(response.data.orders.length);
                  }
            });
      }, []);

      return (
            <>
                  <div className="dashboard__container">
                        <div className="dashboard__content">
                              <SideNavigation />
                              <div className="dashboard__content-container">
                                    <div className="overflow-hidden">
                                          <h2 className="dashboard__content-title">
                                                My Order
                                          </h2>
                                          <table className="table table-bordered table-hover">
                                                <thead className="table-light text-uppercase">
                                                      <tr>
                                                            <td>Id</td>
                                                            <td>Order Time</td>
                                                            <td>Method</td>
                                                            <td>Status</td>
                                                            <td>Total</td>
                                                            <td>Action</td>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {ordersLength > 0 ? (
                                                            orders.map(
                                                                  (
                                                                        order,
                                                                        index
                                                                  ) => (
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <td>
                                                                                    {
                                                                                          order.order_name
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          order.created_date
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          order.method
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <span
                                                                                          className={
                                                                                                order.status ==
                                                                                                "Pending Order"
                                                                                                      ? "text-warning"
                                                                                                      : "text-success"
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                order.status
                                                                                          }
                                                                                    </span>
                                                                              </td>
                                                                              <td>
                                                                                    Rs{" "}
                                                                                    {
                                                                                          order.total
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <Link
                                                                                          to={`/my-orders/${order.slug}`}
                                                                                          className="bage bg-success px-2 rounded-pill text-white pb-1 btn-sm"
                                                                                    >
                                                                                          Detail
                                                                                    </Link>
                                                                              </td>
                                                                        </tr>
                                                                  )
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              6
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No order
                                                                        found
                                                                  </td>
                                                            </tr>
                                                      )}
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Order;
