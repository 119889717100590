import React, { useState, useEffect, useContext } from "react";

import axios from "axios";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import FilterDesktop from "../categories/FilterDesktop";
import FilterCategory from "../categories/FilterCategory";
import Product from "../../components/frontend/filters/Product";

import { Helmet } from "react-helmet";
import CartContext from "../../data/cart-context";

const Search = () => {
      const search = useLocation().search;
      const name = new URLSearchParams(search).get("search");
      const [categories, setCategories] = useState([]);
      const [searchName, setSearchName] = useState("");
      const [products, setProducts] = useState([]);
      const [productLists, setProductLists] = useState([]);
      const [brands, setBrands] = useState([]);
      const [sizes, setSizes] = useState([]);
      const [colors, setColors] = useState([]);
      const [loading, setLoading] = useState(false);

      const [maxAmount, setMaxAmount] = useState(200000);
      const [selectedPrice, setSelectedPrice] = useState([0, maxAmount]);
      const [selectedCategory, setSelectedCategory] = useState(null);

      const cartCtx = useContext(CartContext);
      const login = localStorage.getItem("isLoggedIn");
      const user = JSON.parse(localStorage.getItem("userData"));
      function handleAddCartSubmit(slug, e) {
            if (login) {
                  cartCtx.addCartSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      function handleAddWishlistSubmit(slug, e) {
            if (login) {
                  cartCtx.addWishlistSubmit({
                        slug: slug,
                        email: user.email,
                  });
            } else {
                  toast.success(`Login required!`);
            }
      }

      const handleChangeSizes = (id) => {
            const sizesStateList = sizes;
            const changeCheckedSize = sizesStateList.map((item) =>
                  item.id === id ? { ...item, checked: !item.checked } : item
            );
            console.log(changeCheckedSize);
            setSizes(changeCheckedSize);
      };

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/products?search=${name}`,
                  {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  }
            )
                  .then((response) => {
                        setBrands(response.data.brands);
                        setColors(response.data.colors);
                        setSizes(response.data.sizes);
                        setCategories(response.data.categories);
                        setSearchName(response.data.searchName);
                        setProducts(response.data.products);
                        setProductLists(response.data.products);
                        setMaxAmount(response.data.maxAmount);
                        setSelectedPrice([0, response.data.maxAmount]);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);


      const handleChangePrice = (value) => {
            setSelectedPrice(value);
      };

      const handleChangeCategory = (id) => {
            const cusinesStateList = categories;
            const changeCheckedCuisines = cusinesStateList.map((item) =>
                  item.id === id ? { ...item, checked: !item.checked } : item
            );
            setCategories(changeCheckedCuisines);
      };

      const handleChangeBrand = (id) => {
            const brandsStateList = brands;
            const changeCheckedBrand = brandsStateList.map((item) =>
                  item.id === id ? { ...item, checked: !item.checked } : item
            );
            setBrands(changeCheckedBrand);
      };

      const changePrice = () => {
            let updatedList = productLists;

            // Brand Filter
            const brandsChecked = brands
                  .filter((item) => item.checked)
                  .map((item) => item.title.toLowerCase());

            if (brandsChecked.length) {
                  updatedList = updatedList.filter((item) =>
                        brandsChecked.includes(item.brand.toLowerCase())
                  );
            }

            // Cuisine Filter
            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => item.title.toLowerCase());

            if (cuisinesChecked.length) {
                  updatedList = updatedList.filter((item) =>
                        cuisinesChecked.includes(item.category.toLowerCase())
                  );
            }

            // Price Filter
            const minPrice = selectedPrice[0];
            const maxPrice = selectedPrice[1];

            updatedList = updatedList.filter(
                  (item) => item.price >= minPrice && item.price <= maxPrice
            );

            setProducts(updatedList);
      };

      const url = window.location.href;

      useEffect(() => {
            changePrice();
      }, [selectedPrice, categories, brands, sizes]);

      return (
            <>
                  <Helmet>
                        <title>{`Search by ${searchName} || eSecurity Cart`}</title>
                        <link rel="canonical" href={url} />
                        <meta
                              name="description"
                              content="eSecurity for your protected || Best for security"
                        />
                  </Helmet>
                  <h1 className="d-none">{`Search by ${searchName} || eSecurity Cart`}</h1>
                  <div className="product__details">
                        <div className="product__details-container">
                              <div className="d-flex w-100 category">
                                    <FilterDesktop
                                          sizes={sizes}
                                          brands={brands}
                                          loading={loading}
                                          categories={categories}
                                          selectedPrice={selectedPrice}
                                          handleChangeBrand={handleChangeBrand}
                                          handleChangePrice={handleChangePrice}
                                          handleChangeCategory={
                                                handleChangeCategory
                                          }
                                          handleChangeSizes={handleChangeSizes}
                                          maxAmount={maxAmount}
                                    />
                                    <div className="sliders">
                                          <div className="product__details-content">
                                                <div className="review__row">
                                                      <h2 className="review__row-title">
                                                            <button
                                                                  className="filter__btn d-lg-none"
                                                                  onClick={
                                                                        handleShow
                                                                  }
                                                            >
                                                                  <svg
                                                                        width="18"
                                                                        height="14"
                                                                        className="me-2"
                                                                        viewBox="0 0 18 14"
                                                                  >
                                                                        <path
                                                                              d="M942.581,1295.564H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1295.564,942.581,1295.564Z"
                                                                              transform="translate(-925 -1292.064)"
                                                                              fill="currentColor"
                                                                        ></path>
                                                                        <path
                                                                              d="M942.581,1951.5H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1951.5,942.581,1951.5Z"
                                                                              transform="translate(-925 -1939.001)"
                                                                              fill="currentColor"
                                                                        ></path>
                                                                        <path
                                                                              d="M1163.713,1122.489a2.5,2.5,0,1,0,1.768.732A2.483,2.483,0,0,0,1163.713,1122.489Z"
                                                                              transform="translate(-1158.213 -1122.489)"
                                                                              fill="currentColor"
                                                                        ></path>
                                                                        <path
                                                                              d="M2344.886,1779.157a2.5,2.5,0,1,0,.731,1.768A2.488,2.488,0,0,0,2344.886,1779.157Z"
                                                                              transform="translate(-2330.617 -1769.425)"
                                                                              fill="currentColor"
                                                                        ></path>
                                                                  </svg>
                                                                  <span>
                                                                        Filter
                                                                  </span>
                                                            </button>
                                                            <span className="d-none d-lg-block">
                                                                  {
                                                                        products.length
                                                                  }{" "}
                                                                  items found in{" "}
                                                                  {searchName}
                                                            </span>
                                                      </h2>
                                                      <div className="review__row-filter">
                                                            <div className="review__row-sort">
                                                                  <div className="d-flex align-items-center">
                                                                        <span className="min-w-[50px] text-sm text-body ltr:mr-2 rtl:ml-2">
                                                                              Sort
                                                                              By:
                                                                        </span>
                                                                        <div className=" css-b62m3t-container">
                                                                              <span
                                                                                    id="react-select-2-live-region"
                                                                                    className="css-7pg0cj-a11yText"
                                                                              ></span>
                                                                              <span
                                                                                    aria-live="polite"
                                                                                    aria-atomic="false"
                                                                                    aria-relevant="additions text"
                                                                                    className="css-7pg0cj-a11yText"
                                                                              ></span>
                                                                              <div className=" css-1fxaex9">
                                                                                    <div className=" css-1fo72ad">
                                                                                          <div className=" css-o5sla3-singleValue">
                                                                                                Low
                                                                                                to
                                                                                                High
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="product__content mt-3">
                                                {!loading ? (
                                                      products.map(
                                                            (
                                                                  product,
                                                                  index
                                                            ) => (
                                                                  <Product
                                                                        key={
                                                                              index
                                                                        }
                                                                        index={
                                                                              index
                                                                        }
                                                                        name={
                                                                              product.title
                                                                        }
                                                                        slug={
                                                                              product.slug
                                                                        }
                                                                        image={
                                                                              product.image
                                                                        }
                                                                        onrequest={
                                                                              product.on_request
                                                                        }
                                                                        unit={
                                                                              product.unit
                                                                        }
                                                                        offerPrice={
                                                                              product.offer_price
                                                                        }
                                                                        price={
                                                                              product.price
                                                                        }
                                                                        user={
                                                                              user
                                                                        }
                                                                        handleAddWishlistSubmit={
                                                                              handleAddWishlistSubmit
                                                                        }
                                                                        handleAddCartSubmit={
                                                                              handleAddCartSubmit
                                                                        }
                                                                  />
                                                            )
                                                      )
                                                ) : (
                                                      <>
                                                            <div className="loading">
                                                                  loading
                                                            </div>
                                                      </>
                                                )}
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <FilterCategory
                        show={show}
                        brands={brands}
                        loading={loading}
                        categories={categories}
                        handleClose={handleClose}
                        selectedPrice={selectedPrice}
                        handleChangeBrand={handleChangeBrand}
                        handleChangePrice={handleChangePrice}
                        handleChangeCategory={handleChangeCategory}
                        maxAmount={maxAmount}
                  />
            </>
      );
};

export default Search;
