import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const ProductImage = ({ link, image, name }) => {
      return (
            <>
                  <Link to={link} className="product__item-image">
                        <span className="product__image-container">
                              <span className="product__image-content">
                                    <LazyLoadImage
                                          className="product__image"
                                          effect="blur"
                                          alt={name}
                                          src={image ? image : "no-image.jpg"}
                                          referrerPolicy="no-referrer"
                                    />
                              </span>
                        </span>
                  </Link>
            </>
      );
};

export default ProductImage;
