import React, { useContext, useState } from "react";

import axios from "axios";
import $ from "jquery";
import toast from "react-hot-toast";
import UserContext from "../../data/UserContext";
import { Alert, FormControl } from "react-bootstrap";
import { Col, Row, Spinner } from "reactstrap";
import { SendButton } from "./style";
import { Navigate } from "react-router-dom";
import { SubmitButton } from "../style";

const EmailVerify = () => {
      const [status, setStatus] = useState(false);
      const userCtx = useContext(UserContext);
      const [code, setCode] = useState("");
      const [loading, setLoading] = useState(false);
      const [sendLoading, setSendLoading] = useState(false);

      async function handleTrackForm(event) {
            event.preventDefault();
            setLoading(true);
            $("button[type=submit]").prop("disabled", true);
            if (code === "") {
                  setTimeout(() => {
                        setLoading(false);
                        $("button[type=submit]").prop("disabled", false);
                        toast.error("Please enter your verification code.");
                  }, 3000);
            } else {
                  const data = {
                        code: code,
                  };
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/customer/email/verify`,
                              data,
                              {
                                    headers: {
                                          Authorization: `Bearer ${userCtx?.token}`,
                                    },
                              }
                        )
                        .then((response) => {
                              setTimeout(() => {
                                    setLoading(false);
                                    $("button[type=submit]").prop(
                                          "disabled",
                                          false
                                    );
                                    if (response.data.result === "success") {
                                          return (window.location.href =
                                                "/dashboard");
                                    }

                                    if (response.data.result === "error") {
                                          if (response.data.errorMessage) {
                                                toast.error(
                                                      response.data.errorMessage
                                                );
                                          }
                                    }
                              }, 3000);
                        })
                        .catch((error) => {
                              setTimeout(() => {
                                    setLoading(false);
                                    $("button[type=submit]").prop(
                                          "disabled",
                                          false
                                    );
                                    toast.error(error.message);
                              }, 3000);
                        });
            }
      }

      async function handleMailSend(event) {
            event.preventDefault();
            setSendLoading(true);
            const data = {
                  email: userCtx?.user.email,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/email/verification-notiication`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    setCode("");
                                    setStatus(true);
                                    setSendLoading(false);
                              }

                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                              }
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setSendLoading(false);
                              toast.error(error.message);
                        }, 3000);
                  });
      }

      if (userCtx?.user) {
            if (userCtx?.user?.email_verified_at) {
                  return <Navigate to="/dashboard" />;
            }
      }

      return (
            <>
                  <div className="category__body pt-5 pb-5">
                        <div className="category__content">
                              <div className="category__content-body">
                                    <Row className="justify-content-center">
                                          <Col lg={6}>
                                                <article className="card tracking__card">
                                                      <header className="tracking__card-header">
                                                            {" "}
                                                            Verify Your Email
                                                            Address{" "}
                                                      </header>
                                                      <div>
                                                            {status && (
                                                                  <Alert
                                                                        variant="success"
                                                                        role="alert"
                                                                        dismissible
                                                                  >
                                                                        A fresh
                                                                        verification
                                                                        code has
                                                                        been
                                                                        sent to
                                                                        your
                                                                        email
                                                                        address.
                                                                  </Alert>
                                                            )}
                                                            Please check your
                                                            emails for a message
                                                            with your code.{" "}
                                                            <br /> Your code is
                                                            6 numbers long. If
                                                            you did not receive
                                                            the email
                                                            <SendButton
                                                                  type="button"
                                                                  onClick={
                                                                        handleMailSend
                                                                  }
                                                                  className="btn btn-link ps-1 text-emerald-500 font-bold"
                                                                  data-abc="true"
                                                            >
                                                                  Send Again
                                                                  {sendLoading ? (
                                                                        <Spinner />
                                                                  ) : (
                                                                        ""
                                                                  )}
                                                            </SendButton>
                                                            <form
                                                                  onSubmit={
                                                                        handleTrackForm
                                                                  }
                                                            >
                                                                  <div className="row g-3 mt-2 align-items-center">
                                                                        <div className="form-group col-lg-6">
                                                                              <FormControl
                                                                                    placeholder="Enter code"
                                                                                    type="text"
                                                                                    value={
                                                                                          code
                                                                                    }
                                                                                    onChange={(
                                                                                          event
                                                                                    ) =>
                                                                                          setCode(
                                                                                                event
                                                                                                      .target
                                                                                                      .value
                                                                                          )
                                                                                    }
                                                                              />
                                                                        </div>
                                                                        <div className="form-group col-lg-6">
                                                                              We
                                                                              sent
                                                                              your
                                                                              code
                                                                              to:{" "}
                                                                              <br />
                                                                              {
                                                                                    userCtx
                                                                                          ?.user
                                                                                          .email
                                                                              }
                                                                        </div>
                                                                        <div className="">
                                                                              <SubmitButton type="submit">
                                                                                    {loading ? (
                                                                                          <Spinner />
                                                                                    ) : (
                                                                                          "Continue"
                                                                                    )}
                                                                              </SubmitButton>
                                                                        </div>
                                                                  </div>
                                                            </form>
                                                      </div>
                                                </article>
                                          </Col>
                                    </Row>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default EmailVerify;
