import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "boxicons";
import $ from "jquery";
import { CartContextProvider } from "../data/cart-context";
import { QueryClient, QueryClientProvider } from "react-query";

import PublicRouter from "../routers/PublicRouter";

import { Toaster } from "react-hot-toast";
import ScrollToTop from "../ScrollToTop";

import "overlayscrollbars/overlayscrollbars.css";

import "./style.css";

// Layout
import Loader from "../Loader";
import { FooterContextProvider } from "../data/FooterContext";
import { MetaContextProvider } from "../data/MetaContext";
import { UserContextProvider } from "../data/UserContext";
const Header = lazy(() => import("../layouts/Header"));
const Navigation = lazy(() => import("../layouts/Navigation"));
const Footer = lazy(() => import("../layouts/Footer"));
const AppFooter = lazy(() => import("../layouts/AppFooter"));

const queryClient = new QueryClient();

function App() {
      useEffect(() => {
            $(window).on("load", function () {
                  $(".home1preloader").delay(2000).fadeOut("slow");
            });
      }, []);

      // useEffect(() => {
      //       const script = document.createElement("script");
      //       script.src = "/js/chat.js";
      //       script.async = true;
      //       script.async = true;
      //       document.body.appendChild(script);
      //       return () => {
      //             document.body.removeChild(script);
      //       };
      // }, []);

      // if (loading) {
      //       return (
      //             <>
      //                   <Loader />
      //             </>
      //       );
      // }
      return (
            <Router>
                  <ScrollToTop />
                  <MetaContextProvider>
                        <UserContextProvider>
                              <FooterContextProvider>
                                    <QueryClientProvider client={queryClient}>
                                          <CartContextProvider>
                                                {/* <Loader /> */}
                                                <Toaster position="top-right" />
                                                <header>
                                                      <Header />
                                                      <Navigation />
                                                </header>
                                                <div>
                                                      <Suspense
                                                            fallback={
                                                                  <Loader />
                                                            }
                                                      >
                                                            <PublicRouter />
                                                      </Suspense>
                                                </div>
                                                <AppFooter />
                                                <Footer />
                                          </CartContextProvider>
                                    </QueryClientProvider>
                              </FooterContextProvider>
                        </UserContextProvider>
                  </MetaContextProvider>
            </Router>
      );
}

export default App;
