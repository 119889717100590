import React, { useState, useEffect } from "react";

import axios from "axios";
import "./orderStyle.css";
import { Link, useParams } from "react-router-dom";

const OrderDetail = () => {
      const { orderId } = useParams();
      const [loading, setLoading] = useState(false);
      const [order, setOrder] = useState([]);
      const [orders, setOrders] = useState([]);
      const [shipping, setShipping] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/user/myorders/${orderId}`
                  )
                  .then((response) => {
                        setOrder(response.data.order);
                        setOrders(response.data.orders);
                        setShipping(response.data.shipping);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="invoice__container">
                        <div className="invoice__content">
                              <div>
                                    <div className="bg-indigo-50 invoice__content-container">
                                          <div className="invoice__content-body">
                                                <h1 className="invoice__content-body_title">
                                                      Invoice
                                                </h1>
                                                <div className="invoice__content-body_details text-left">
                                                      <h2 className="invoice__content-body_image">
                                                            <Link
                                                                  className=""
                                                                  to="/"
                                                            >
                                                                  <span className="product__image-container">
                                                                        <span className="product__image-content">
                                                                              <img
                                                                                    alt=""
                                                                                    aria-hidden="true"
                                                                                    style={{
                                                                                          width: "120px",
                                                                                    }}
                                                                                    src="/images/logo.png"
                                                                                    className="product__image"
                                                                                    referrerPolicy="no-referrer"
                                                                              />
                                                                        </span>
                                                                  </span>
                                                            </Link>
                                                      </h2>
                                                      <p className="invoice__content-body__summary">
                                                            Kalanki <br />
                                                            Kathmandu, Nepal{" "}
                                                      </p>
                                                </div>
                                          </div>
                                          <div className="invoice__details">
                                                <div className="invoice__details-content">
                                                      <span className="invoice__details-title">
                                                            Date
                                                      </span>
                                                      <span className="invoice__details-summary">
                                                            <span>
                                                                  {order.date}
                                                            </span>
                                                      </span>
                                                </div>
                                                <div className="invoice__details-content">
                                                      <span className="invoice__details-title">
                                                            Invoice No.
                                                      </span>
                                                      <span className="invoice__details-summary">
                                                            {order.order_name}
                                                      </span>
                                                </div>
                                                <div className="invoice__details-description">
                                                      <span className="invoice__details-title">
                                                            Invoice To.
                                                      </span>
                                                      <span className="invoice__details-summary">
                                                            {shipping.street}
                                                            <br />
                                                            {
                                                                  shipping.city
                                                            },{" "}
                                                            {shipping.country}
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                                    <div>
                                          <div className="invoice__table">
                                                <div className="invoice__table-container">
                                                      <table className="invoice__table-content">
                                                            <thead className="bg-gray-50">
                                                                  <tr className="text-xs bg-gray-100">
                                                                        <th
                                                                              scope="col"
                                                                              className="invoice__table-header text-start"
                                                                        >
                                                                              Sr.
                                                                        </th>
                                                                        <th
                                                                              scope="col"
                                                                              className="invoice__table-header text-start"
                                                                        >
                                                                              Product
                                                                              Name
                                                                        </th>
                                                                        <th
                                                                              scope="col"
                                                                              className="invoice__table-header text-center"
                                                                        >
                                                                              Color
                                                                        </th>
                                                                        <th
                                                                              scope="col"
                                                                              className="invoice__table-header text-center"
                                                                        >
                                                                              Size
                                                                        </th>
                                                                        <th
                                                                              scope="col"
                                                                              className="invoice__table-header text-center"
                                                                        >
                                                                              Quantity
                                                                        </th>
                                                                        <th
                                                                              scope="col"
                                                                              className="invoice__table-header text-end"
                                                                        >
                                                                              Amount
                                                                        </th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-100 text-serif text-sm">
                                                                  {orders ? (
                                                                        orders.map(
                                                                              (
                                                                                    item,
                                                                                    index
                                                                              ) => (
                                                                                    <tr>
                                                                                          <th className="invoice__table-data">
                                                                                                {
                                                                                                      ++index
                                                                                                }{" "}
                                                                                          </th>
                                                                                          <td className="invoice__table-name">
                                                                                                <span>
                                                                                                      <div className="d-flex align-items-center">
                                                                                                            <div className="flex-shrink-0 me-3">
                                                                                                                  <div className="avatar-sm bg-light rounded p-1 h-100">
                                                                                                                        <img
                                                                                                                              style={{
                                                                                                                                    height: "40px",
                                                                                                                                    weight: "40px",
                                                                                                                              }}
                                                                                                                              src={
                                                                                                                                    item.image
                                                                                                                              }
                                                                                                                              alt={
                                                                                                                                    item.name
                                                                                                                              }
                                                                                                                              className="img-fluid d-block"
                                                                                                                              referrerPolicy="no-referrer"
                                                                                                                        />
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                            {
                                                                                                                  item.name
                                                                                                            }
                                                                                                      </div>
                                                                                                </span>
                                                                                          </td>
                                                                                          <td className="invoice__table-quantity invoice__table-price">
                                                                                                {
                                                                                                      item.color
                                                                                                }{" "}
                                                                                          </td>
                                                                                          <td className="invoice__table-quantity invoice__table-price">
                                                                                                {
                                                                                                      item.size
                                                                                                }{" "}
                                                                                          </td>
                                                                                          <td className="invoice__table-quantity">
                                                                                                {
                                                                                                      item.quantity
                                                                                                }{" "}
                                                                                          </td>
                                                                                          <td className="invoice_table-total">
                                                                                                Rs{" "}
                                                                                                {
                                                                                                      item.amount
                                                                                                }
                                                                                          </td>
                                                                                    </tr>
                                                                              )
                                                                        )
                                                                  ) : (
                                                                        <tr>
                                                                              <td
                                                                                    colSpan="5"
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    product
                                                                                    found
                                                                              </td>
                                                                        </tr>
                                                                  )}
                                                            </tbody>
                                                      </table>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="invoice__payment bg-emerald-50">
                                          <div className="invoice__payment-content">
                                                <div className="invoice__details-content sm:flex-wrap">
                                                      <span className="mb-1 invoice__details-title">
                                                            Payment Method
                                                      </span>
                                                      <span className="text-sm text-gray-500 font-semibold font-serif block">
                                                            COD
                                                      </span>
                                                </div>
                                                <div className="invoice__details-content sm:flex-wrap">
                                                      <span className="mb-1 invoice__details-title">
                                                            Shipping Cost
                                                      </span>
                                                      <span className="text-sm text-gray-500 font-semibold font-serif block">
                                                            Rs 0.00
                                                      </span>
                                                </div>
                                                <div className="invoice__details-content sm:flex-wrap">
                                                      <span className="mb-1 invoice__details-title">
                                                            Discount
                                                      </span>
                                                      <span className="text-sm text-gray-500 font-semibold font-serif block">
                                                            Rs 0.00
                                                      </span>
                                                </div>
                                                <div className="flex flex-col sm:flex-wrap">
                                                      <span className="mb-1 invoice__details-title">
                                                            Total Amount
                                                      </span>
                                                      <span className="text-2xl font-serif font-bold text-red-500 block">
                                                            Rs {order.total}
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default OrderDetail;
