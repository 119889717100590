import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import $ from "jquery";
import SingleForm from "./SingleForm";
import CompanyForm from "./CompanyForm";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import FormButton from "../form/FormButton";

const RequestForm = ({ show, handleClose, productId }) => {
      const [type, setType] = useState("");
      const [disable, setDisable] = useState(false);

      function handleType() {
            setDisable(type);
      }

      useEffect(() => {
            handleType();
      }, [type]);

      const [loading, setLoading] = useState(false);
      const [fullName, setFullName] = useState("");
      const [emailAddress, setEmailAddress] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [companyName, setCompanyName] = useState("");
      const [address, setAddress] = useState("");
      const [message, setMessage] = useState("");

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  type: type,
                  full_name: fullName,
                  email_address: emailAddress,
                  phone_number: phoneNumber,
                  company_name: companyName,
                  address: address,
                  message: message,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${productId}`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTimeout(() => {
                                    setLoading(false);
                                    handleClose();
                                    $("form").trigger("reset");
                                    setFullName("");
                                    setEmailAddress("");
                                    setPhoneNumber("");
                                    setCompanyName("");
                                    setAddress("");
                                    setMessage("");
                                    setType("");
                                    toast.success(response.data.message);
                              }, 3000);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message) {
                                    if (response.data.message.type) {
                                          toast.error(
                                                response.data.message.type
                                          );
                                    }
                                    if (response.data.message.full_name) {
                                          toast.error(
                                                response.data.message.full_name
                                          );
                                    }
                                    if (response.data.message.email_address) {
                                          toast.error(
                                                response.data.message
                                                      .email_address
                                          );
                                    }
                                    if (response.data.message.phone_number) {
                                          toast.error(
                                                response.data.message
                                                      .phone_number
                                          );
                                    }
                                    if (response.data.message.company_name) {
                                          toast.error(
                                                response.data.message
                                                      .company_name
                                          );
                                    }
                                    if (response.data.message.address) {
                                          toast.error(
                                                response.data.message.address
                                          );
                                    }
                                    if (response.data.message.message) {
                                          toast.error(
                                                response.data.message.message
                                          );
                                    }
                              }
                        }
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                        }, 3000);
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Modal
                        show={show}
                        size="lg"
                        backdrop="static"
                        onHide={handleClose}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Request Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <Form onSubmit={handleForm}>
                                    <Row className="g-3">
                                          <Col lg={12}>
                                                <FormLabel>
                                                      User Type{" "}
                                                      <span className="text-danger">
                                                            *
                                                      </span>
                                                </FormLabel>
                                                <select
                                                      className="form-select"
                                                      onChange={(event) =>
                                                            setType(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                >
                                                      <option value="">
                                                            Select One
                                                      </option>
                                                      <option value="Single">
                                                            Single
                                                      </option>
                                                      <option value="Company">
                                                            Company
                                                      </option>
                                                </select>
                                          </Col>
                                          {disable === "Single" && (
                                                <SingleForm
                                                      fullName={fullName}
                                                      emailAddress={
                                                            emailAddress
                                                      }
                                                      phoneNumber={phoneNumber}
                                                      address={address}
                                                      setFullName={setFullName}
                                                      setEmailAddress={
                                                            setEmailAddress
                                                      }
                                                      setPhoneNumber={
                                                            setPhoneNumber
                                                      }
                                                      setAddress={setAddress}
                                                />
                                          )}

                                          {disable === "Company" && (
                                                <CompanyForm
                                                      fullName={fullName}
                                                      emailAddress={
                                                            emailAddress
                                                      }
                                                      phoneNumber={phoneNumber}
                                                      companyName={companyName}
                                                      address={address}
                                                      setFullName={setFullName}
                                                      setEmailAddress={
                                                            setEmailAddress
                                                      }
                                                      setPhoneNumber={
                                                            setPhoneNumber
                                                      }
                                                      setCompanyName={
                                                            setCompanyName
                                                      }
                                                      setAddress={setAddress}
                                                />
                                          )}

                                          <Col lg={12}>
                                                <FormLabel>Message</FormLabel>
                                                <FormControl
                                                      as="textarea"
                                                      rows={6}
                                                      onChange={(event) =>
                                                            setMessage(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <FormButton
                                                      classes="mx-0"
                                                      title="Submit"
                                                      loading={loading}
                                                />
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default RequestForm;
