import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

import PriceSlider from "../../components/frontend/filters/PriceSlider";
import RelatedBrand from "../../components/frontend/filters/RelatedBrand";
import RelatedCategory from "../../components/frontend/filters/RelatedCategory";

const FilterCategory = ({
      categories,
      loading,
      show,
      handleClose,
      brands,
      handleChangeBrand,
      selectedPrice,
      handleChangePrice,
      handleChangeCategory,
      maxAmount,
}) => {
      const [subMenu, setSubMenu] = useState(null);

      function handleSubMenu(index) {
            setSubMenu(index);
      }
      return (
            <>
                  <Offcanvas show={show} onHide={handleClose}>
                        <div className="offcanvas__header bg-emerald-500">
                              <h2 className="offcanvas__header-title">
                                    <Link
                                          className="offcanvas__header-right"
                                          to="/"
                                    >
                                          <span
                                                className="product__image-container"
                                                style={{ maxWidth: "112px" }}
                                          >
                                                <span className="product__image-content">
                                                      <img
                                                            alt=""
                                                            src="/images/white-logo.png"
                                                            className="product__image"referrerPolicy="no-referrer"
                                                      />
                                                </span>
                                          </span>
                                    </Link>
                              </h2>
                              <button
                                    onClick={handleClose}
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    className="offcanvas__header-btn"
                              >
                                    <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 512 512"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                    >
                                          <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path>
                                    </svg>
                              </button>
                        </div>
                        <Offcanvas.Body className="p-0">
                              <div className="offcanvas__body">
                                    <h2 className="offcanvas__body-title">
                                          Related Category
                                    </h2>
                                    <div className="offcanvas__body-container">
                                          <RelatedCategory
                                                loading={loading}
                                                categories={categories}
                                                handleChangeCategory={
                                                      handleChangeCategory
                                                }
                                          />
                                    </div>
                                    <h2 className="offcanvas__body-title">
                                          Brand
                                    </h2>
                                    <div className="offcanvas__body-container">
                                          <RelatedBrand
                                                brands={brands}
                                                loading={loading}
                                                changeChecked={
                                                      handleChangeBrand
                                                }
                                          />
                                    </div>
                                    <h2 className="offcanvas__body-title">
                                          Price
                                    </h2>
                                    <div className="offcanvas__body-container pt-6">
                                          <PriceSlider
                                                value={selectedPrice}
                                                maxAmount={maxAmount}
                                                changePrice={handleChangePrice}
                                          />
                                    </div>
                              </div>
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default FilterCategory;
