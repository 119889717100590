import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { createContext, useState, useEffect } from "react";

const CartContext = createContext({
      wishlists: [],
      totalWishlist: 0,
      addWishlistSubmit: (slug, e) => {},
      removeWishlistSubmit: (slug, e) => {},
      carts: [],
      totalCart: 0,
      cartSubTotal: 0,
      discountAmount: 0,
      totalAmount: 0,
      chasbackAmount: 0,
      addCartSubmit: (slug, e) => {},
      removeCartSubmit: (slug, e) => {},
      updateCartSubmit: (slug, e) => {},
});

export function CartContextProvider(props) {
      const [userCartCount, setUserCartCount] = useState([]);
      const [userCarts, setUserCarts] = useState([]);
      const [cartSubTotal, setCartSubTotal] = useState([]);
      const [discountAmount, setDiscountAmount] = useState(0);
      const [cashbackAmount, setCashbackAmount] = useState(0);
      const [totalAmount, setTotalAmount] = useState(0);

      const [userWishlistCount, setUserWishlistCourt] = useState([]);
      const [userWishlists, setUserWishlists] = useState([]);

      const [email, setEmail] = useState("");

      const user = JSON.parse(localStorage.getItem("userData"));

      const loadData = async () => {
            await axios
                  .post(`${process.env.REACT_APP_SECRET_KEY}/api/carts/list`, {
                        email: user ? user.email : "",
                  })
                  .then((response) => {
                        setUserCartCount(response.data.cartCount);
                        setUserCarts(response.data.carts);
                        setCartSubTotal(response.data.cartSubTotal);
                        setDiscountAmount(response.data.discountAmount);
                        setCashbackAmount(response.data.cashbackAmount);
                        setTotalAmount(response.data.totalAmount);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/wishlists/count`,
                        { email: user ? user.email : "" }
                  )
                  .then((response) => {
                        setUserWishlistCourt(response.data.wishlists.length);
                        setUserWishlists(response.data.wishlists);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      async function addWishlistSubmit(data) {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/wishlists/create`,
                        data
                  )
                  .then((response) => {
                        setUserWishlistCourt(response.data.wishlists.length);
                        setUserWishlists(response.data.wishlists);
                        toast.success(`Added to wishlist!`);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      }

      async function removeWishlistSubmit(data) {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/wishlists/remove`,
                        data
                  )
                  .then((response) => {
                        setUserWishlistCourt(response.data.wishlists.length);
                        setUserWishlists(response.data.wishlists);
                        toast.error(`Removed from wishlist!`);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      }

      async function addCartSubmit(data) {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/carts/create`,
                        data
                  )
                  .then((response) => {
                        setUserCartCount(response.data.cartCount);
                        setUserCarts(response.data.carts);
                        setCartSubTotal(response.data.cartSubTotal);
                        setDiscountAmount(response.data.discountAmount);
                        setCashbackAmount(response.data.cashbackAmount);
                        setTotalAmount(response.data.totalAmount);
                        toast.success(`Added to bag!`);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      }

      async function updateCartSubmit(data) {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/carts/update`,
                        data
                  )
                  .then((response) => {
                        setUserCartCount(response.data.cartCount);
                        setUserCarts(response.data.carts);
                        setCartSubTotal(response.data.cartSubTotal);
                        setDiscountAmount(response.data.discountAmount);
                        setCashbackAmount(response.data.cashbackAmount);
                        setTotalAmount(response.data.totalAmount);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      }

      async function removeCartSubmit(data) {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/carts/remove`,
                        data
                  )
                  .then((response) => {
                        setUserCartCount(response.data.cartCount);
                        setUserCarts(response.data.carts);
                        setCartSubTotal(response.data.cartSubTotal);
                        setDiscountAmount(response.data.discountAmount);
                        setCashbackAmount(response.data.cashbackAmount);
                        setTotalAmount(response.data.totalAmount);
                        toast.success(`Removed from bag!`);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      }

      const context = {
            carts: userCarts,
            totalCart: userCartCount,
            addCartSubmit: addCartSubmit,
            removeCartSubmit: removeCartSubmit,
            cartSubTotal: cartSubTotal,
            discountAmount: discountAmount,
            cashbackAmount: cashbackAmount,
            totalAmount: totalAmount,

            wishlists: userWishlists,
            totalWishlist: userWishlistCount,
            addWishlistSubmit: addWishlistSubmit,
            removeWishlistSubmit: removeWishlistSubmit,
            updateCartSubmit: updateCartSubmit,
      };

      return (
            <CartContext.Provider value={context}>
                  {props.children}
            </CartContext.Provider>
      );
}

export default CartContext;
