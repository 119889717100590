import React, { useContext, useState } from "react";
import "./style.css";

import axios from "axios";

import TrackItem from "./TrackItem";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import MetaContext from "../../data/MetaContext";
import { Col, Container, Form, Row } from "react-bootstrap";

const TrackingOrder = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("track-your-order");

      const [email, setEmail] = useState("");
      const [orderNumber, setOrderNumber] = useState("");

      const [order, setOrder] = useState([]);
      const [orderItems, setOrderItems] = useState([]);
      const [status, setStatus] = useState(false);

      async function handleTrackForm(event) {
            event.preventDefault();

            if (email === "" || orderNumber === "") {
                  toast.error("Please enter your email & order number.");
            } else {
                  const data = {
                        email: email,
                        orderNumber: orderNumber,
                  };
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/order/tracking`,
                              data,
                              {
                                    headers: {
                                          apikey: process.env.REACT_APP_API_KEY,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.result === "success") {
                                    setStatus(true);
                                    setOrder(response.data.order);
                                    setOrderItems(response.data.orderItems);
                                    setEmail("");
                                    setOrderNumber("");
                              }

                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }

                                    if (response.data.message[0]) {
                                          toast.error(response.data.message[0]);
                                    }
                                    if (response.data.message[1]) {
                                          toast.error(response.data.message[1]);
                                    }
                                    if (response.data.message[2]) {
                                          toast.error(response.data.message[2]);
                                    }
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
      }

      function handleStatus() {
            setStatus(false);
      }

      return (
            <>
                  <h1 className="d-none">
                        {metaCtx.meta.seo_title || metaCtx.meta.title}
                  </h1>
                  <Container fluid="lg">
                        <div className="category__content-header clearfix d-flex align-items-center mt-2">
                              <div className="product__breadcrumb d-none d-md-block">
                                    <ol className="product__breadcrumb-content">
                                          <li className="product__breadcrumb-link hover:text-emerald-500">
                                                <Link href="/">Home</Link>
                                          </li>
                                          <li className="product__breadcrumb-item">
                                                <svg
                                                      stroke="currentColor"
                                                      fill="none"
                                                      strokeWidth="2"
                                                      viewBox="0 0 24 24"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      height="1em"
                                                      width="1em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                >
                                                      <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                          </li>
                                          <li className="product__breadcrumb-title">
                                                Track Your Order
                                          </li>
                                    </ol>
                              </div>
                        </div>
                  </Container>
                  <div className="category__body">
                        <div className="category__content">
                              <div className="mb-5">
                                    <Container>
                                          <Row className="justify-content-center">
                                                {!status && (
                                                      <Col lg={8}>
                                                            <article className="tracking__card">
                                                                  <header className="tracking__card-header">
                                                                        {" "}
                                                                        Track
                                                                        Your
                                                                        Order{" "}
                                                                  </header>
                                                                  <div className="card-body">
                                                                        <Form
                                                                              onSubmit={
                                                                                    handleTrackForm
                                                                              }
                                                                        >
                                                                              <div className="row g-3">
                                                                                    <div className="form-group col-lg-6">
                                                                                          <label className="form-label">
                                                                                                Your
                                                                                                email
                                                                                          </label>
                                                                                          <input
                                                                                                className="form-control"
                                                                                                type="email"
                                                                                                onChange={(
                                                                                                      event
                                                                                                ) =>
                                                                                                      setEmail(
                                                                                                            event
                                                                                                                  .target
                                                                                                                  .value
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </div>
                                                                                    <div className="form-group col-lg-6">
                                                                                          <label className="form-label">
                                                                                                Your
                                                                                                order
                                                                                                number
                                                                                          </label>
                                                                                          <input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                onChange={(
                                                                                                      event
                                                                                                ) =>
                                                                                                      setOrderNumber(
                                                                                                            event
                                                                                                                  .target
                                                                                                                  .value
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </div>
                                                                                    <div className="">
                                                                                          <button
                                                                                                type="submit"
                                                                                                className="btn btn-warning"
                                                                                                data-abc="true"
                                                                                          >
                                                                                                Track
                                                                                                Now
                                                                                                <i className="fa fa-chevron-circle-right hvr-icon ps-2"></i>
                                                                                          </button>
                                                                                    </div>
                                                                              </div>
                                                                        </Form>
                                                                  </div>
                                                            </article>
                                                      </Col>
                                                )}

                                                {status && (
                                                      <TrackItem
                                                            order={order}
                                                            orderItems={
                                                                  orderItems
                                                            }
                                                            handleStatus={
                                                                  handleStatus
                                                            }
                                                      />
                                                )}
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default TrackingOrder;
