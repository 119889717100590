import React from "react";

const TrackItem = ({ order, orderItems, handleStatus }) => {
    return (
        <>
        <div className="col-lg-12">
            <article className="card tracking__card">
                <header className="tracking__card-header"> My Orders / Tracking </header>
                <div className="card-body">
                    <h6>Order ID: {order.order_name}</h6>
                    <article className="card tracking__card">
                        <div className="card-body row">
                            <div className="col"> <strong>Estimated Delivery time:</strong> <br />{order.date} </div>
                            <div className="col"> <strong>Shipping BY:</strong> <br /> {order.address} | <i className="fa fa-phone"></i> {order.phone_number} </div>
                            <div className="col"> <strong>Status:</strong> <br /> Picked by the courier </div>
                            <div className="col"> <strong>Tracking #:</strong> <br /> {order.order_name} </div>
                        </div>
                    </article>
                    <div className="track">
                        <div className={`step ${(order.status === 'Confirm') && 'active'}`}> <span className="icon"> <i className="fa fa-check"></i> </span> <span className="text">Order confirmed</span> </div>
                        <div className={`step ${(order.status === 'Shipping') && 'active'}`}> <span className="icon"> <i className="fa fa-user"></i> </span> <span className="text"> Picked by courier</span> </div>
                        <div className={`step ${(order.status === 'On Way') && 'active'}`}> <span className="icon"> <i className="fa fa-truck"></i> </span> <span className="text"> On the way </span> </div>
                        <div className={`step ${(order.status === 'Delivered') && 'active'}`}> <span className="icon"> <i className="fa fa-box"></i> </span> <span className="text">Ready for pickup</span> </div>
                    </div>
                    <hr />
                    <ul className="row">
                        {orderItems && (
                            orderItems.map((item, index) => (
                                <li className="col-md-4" key={index}>
                                    <figure className="itemside mb-3">
                                        <div className="aside"><img src="https://i.imgur.com/iDwDQ4o.png" referrerPolicy="no-referrer" className="img-sm border" /></div>
                                        <figcaption className="info align-self-center">
                                            <p className="title">{item.name}</p> <span className="text-muted">Rs {item.amount} </span>
                                        </figcaption>
                                    </figure>
                                </li>
                            ))
                        )}
                    </ul>
                    <hr />
                    <button className="btn btn-warning" onClick={handleStatus} data-abc="true"> 
                        <i className="fa fa-chevron-circle-left hvr-icon pe-2"></i> Back to orders
                    </button>
                </div>
            </article>
        </div>
        </>
    )
}

export default TrackItem;